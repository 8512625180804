import React, { useState } from 'react';
import { FaPhone, FaTimes, FaMapMarkerAlt, FaGift, FaCalendarCheck } from 'react-icons/fa';

import DemoContacts from './DemoContacts';
import DemoLocation from './DemoLocation';
import DemoGift from './DemoGift';
import DemoDate from './DemoDate';

function DemoBottomBar() {
  const [openModalType, setOpenModalType] = useState(null);

  const toggleModal = (modalType) => {
    setOpenModalType((prevModalType) =>
      prevModalType === modalType ? null : modalType
    );
  };

  const handleCloseModal = () => {
    setOpenModalType(null);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred on the overlay (background)
    if (e.target.classList.contains('overlay')) {
      handleCloseModal();
    }
  };

  return (
    <div>
      <div className="fixed bottom-0 left-0 w-full shadow-md py-2 z-30 bg-white/70">
        <div className="flex items-center justify-between md:justify-center px-6 space-x-2">
          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-5"
            onClick={() => toggleModal('contacts')}
          >
            <FaPhone className="contact text-black text-2xl" />
          </button>

          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-5"
            onClick={() => toggleModal('location')}
          >
            <FaMapMarkerAlt className="contact text-black text-2xl" />
          </button>

          {/* <a
            rel="noopener noreferrer"
            className="flex items-center justify-center p-5"
            href="https://mycollection.shop/ideahadiahkahwin/"
            target="_blank"
          >
            <FaGift className="contact text-black text-2xl" />
          </a> */}

          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-5"
            onClick={() => toggleModal('date')}
          >
            <FaCalendarCheck className="contact text-black text-2xl" />
          </button>
          <a 
            className={`py-3 px-3 md:px-5 bg-secondary-500 text-black hover:bg-red-600 hover:text-neutral-50 rounded-lg`} 
            // onClick={() => toggleModal('publish')}
            href={`/addcontacts`}
          >
              Next
          </a>
        </div>
      </div>

      {openModalType === 'contacts' && (
        <div
          className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <DemoContacts />
          </div>
        </div>
      )}

      {openModalType === 'location' && (
        <div
          className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <DemoLocation />
          </div>
        </div>
      )}

      {openModalType === 'gift' && (
        <div
          className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <DemoGift />
          </div>
        </div>
      )}

      {openModalType === 'date' && (
        <div
          className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <DemoDate />
          </div>
        </div>
      )}
    </div>
  );
}

export default DemoBottomBar;
