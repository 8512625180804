import React, { useState, useRef } from 'react';
import axios from 'axios';

const MusicActivation = () => {
  const textareaRef = useRef(null);
  const [cardText, setCardText]= useState('');
  const [success, setSuccess]= useState(false);

  // Regular expression to match the pattern "ID: <alphanumeric>"
  const regex = /ID: (\w+)/;

  // Extract the match using the regular expression
  const match = cardText.match(regex);

  // Check if there is a match and get the value
  const cardId = match ? match[1] : null;

  console.log(cardId);

  const message = 
    `Terima kasih membeli. 😊
    
    Lagu telah diaktifkan.
    
    Boleh tetapkan lagu melalui pautan berikut:
    https://invitekahwin.com/music/${cardId}
    
    Jika ada apa-apa masalah, boleh maklumkan kepada saya. 😊`

  const handleCopyClick = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand('copy');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios.put(`${process.env.REACT_APP_API_BASE_URL}/activate-music/${cardId}`, { 
      music_activation: true,
      music: true, 
    })
      .then(response => {
        setSuccess(true);
        console.log(response.data);
      })
      .catch(error => {
          console.error('Error activate music:', error);
      });
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <form onSubmit={handleSubmit}>
        <label className="block mb-2 text-sm font-bold text-gray-600">Card ID:</label>
        <input
          type="text"
          value={cardId}
          onChange={(e) => setCardText(e.target.value)}
          className="w-full px-4 py-2 mb-4 border rounded-md"
          placeholder="Enter Text"
        />

        <button
          type="submit"
          className="w-full px-4 py-2 text-neutral-50 bg-blue-500 rounded-md hover:bg-blue-600"
        >
          Aktifkan Lagu
        </button>
      </form>
      {success && (
        <div>
          <label className="block mb-2 text-sm font-bold text-gray-600">Success</label>
          <textarea
            ref={textareaRef}
            value={message}
            className="w-full px-4 py-2 mb-4 border rounded-md"
            readOnly
            style={{ whiteSpace: 'pre-line' }}
          />
          <button onClick={handleCopyClick} className="px-4 py-2 bg-blue-500 text-neutral-50 rounded-md">
            Copy Text
          </button>
        </div>
      )}
    </div>
  );
};

export default MusicActivation;
