import Images from "../assets/Images";
import Fonts from "../assets/Fonts";

const themes = {
  theme1: {
    bg: Images.bg1,
    ft: Images.ft1,
    fb: Images.fb1,
    fc: '',
    ftStyle: `absolute top-0 right-0 md:w-1/3 sm:w-1/2 w-2/3`,
    fbStyle: `absolute bottom-0 left-0 md:w-1/3 sm:w-1/2 w-2/3`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-[#FFF7F2]',
    cd1: "bg-red-200 p-4 rounded-lg",
    cd2: "bg-red-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme2: {
    bg: Images.bg2,
    ft: Images.ft2,
    fb: Images.fb2,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/5 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/5 w-full`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-[#F4F9F5]',
    cd1: "bg-green-200 p-4 rounded-lg",
    cd2: "bg-green-300 p-4 rounded-lg",
    cd3: "bg-green-400 p-4 rounded-lg",
    cd4: "bg-green-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme3: {
    bg: Images.bg3,
    ft: Images.ft3,
    fb: Images.fb3,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-1/3 md:w-1/5 w-1/3`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/5 w-full`,
    fcStyle: `text-center z-30 p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-[#EAF3EC]',
    cd1: "bg-green-200 p-4 rounded-lg",
    cd2: "bg-green-300 p-4 rounded-lg",
    cd3: "bg-green-400 p-4 rounded-lg",
    cd4: "bg-green-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme4: {
    bg: Images.bg4,
    ft: Images.ft4,
    fb: Images.fb4,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-2/5 w-4/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/3 w-2/3`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme5: {
    bg: Images.bg5,
    ft: Images.ft5,
    fb: Images.fb5,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-1/3 md:w-1/4 w-2/5`,
    fbStyle: `absolute bottom-0 left-0 sm:w-1/3 md:w-1/4 w-2/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme6: {
    bg: Images.bg6,
    ft: Images.ft6,
    fb: Images.fb6,
    fc: Images.fc6,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme7: {
    bg: Images.bg7,
    ft: Images.ft7,
    fb: Images.fb7,
    fc: Images.fc7,
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f9.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme8: {
    bg: Images.bg8,
    ft: Images.ft8,
    fb: Images.fb8,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/3 w-3/4`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/3 w-3/4`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme9: {
    bg: Images.bg9,
    ft: '',
    fb: '',
    fc: Images.fc9,
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-20 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme10: {
    bg: Images.bg10,
    ft: '',
    fb: '',
    fc: Images.fc10,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-40 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-gray-100',
    cd1: "bg-green-200 p-4 rounded-lg",
    cd2: "bg-green-300 p-4 rounded-lg",
    cd3: "bg-green-400 p-4 rounded-lg",
    cd4: "bg-green-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme11: {
    bg: Images.bg11,
    ft: Images.ft11,
    fb: Images.fb11,
    fc: Images.fc11,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-2/3`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-2/3`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-neutral-50 space-y-32 md:space-y-8`,
    tStyle: `text-center text-neutral-50 p-10 md:px-20 md:py-10 border border-yellow-200`,
    xTopStyle: 100,
    xBottomStyle: -100,
    textColor: `text-neutral-50`,
    themeColor: 'bg-slate-900',
    cd1: "bg-yellow-100 p-4 rounded-lg",
    cd2: "bg-yellow-200 p-4 rounded-lg",
    cd3: "bg-yellow-300 p-4 rounded-lg",
    cd4: "bg-yellow-400 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme12: {
    bg: Images.bg12,
    ft: '',
    fb: '',
    fc: Images.fc12,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-56 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 bg-white shadow-lg`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-red-100',
    cd1: "bg-red-200 p-4 rounded-lg",
    cd2: "bg-red-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-4xl",
    fontP: Fonts.f7.fontP,
  },
  theme13: {
    bg: Images.bg13,
    ft: Images.ft13,
    fb: Images.fb13,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-blue-100 space-y-8 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 text-blue-100 border border-blue-200`,
    xTopStyle: 100,
    xBottomStyle: -100,
    textColor: `text-blue-100`,
    themeColor: 'bg-cyan-950',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme14: {
    bg: Images.bg14,
    ft: Images.ft14,
    fb: Images.fb14,
    fc: Images.fc14,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-44 md:space-y-8`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-purple-300`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-blue-100',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f18.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme15: {
    bg: Images.bg15,
    ft: '',
    fb: '',
    fc: Images.fc15,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-44 md:space-y-8`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-secondary-500`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme16: {
    bg: Images.bg16,
    ft: '',
    fb: '',
    fc: Images.fc16,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-40 md:space-y-4`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-gray-600`,
    xTopStyle: '',
    xBottomStyle: '',
    textColor: `text-black`,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme17: {
    bg: Images.bg17,
    ft: '',
    fb: '',
    fc: Images.fc17,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-52 md:w-1/2 sm:w-2/3 w-full text-yellow-100 space-y-52 md:space-y-8`,
    tStyle: `text-center text-yellow-100 p-10 md:px-20 md:py-10 border border-yellow-100`,
    xTopStyle: '',
    xBottomStyle: '',
    textColor: `text-yellow-100`,
    themeColor: 'bg-gray-800',
    cd1: "bg-yellow-100 p-4 rounded-lg",
    cd2: "bg-yellow-200 p-4 rounded-lg",
    cd3: "bg-yellow-300 p-4 rounded-lg",
    cd4: "bg-yellow-400 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme18: {
    bg: Images.bg18,
    ft: Images.ft18,
    fb: Images.fb18,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/4 w-4/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/4 w-4/5`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-8 md:space-y-8`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-gray-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    textColor: `text-black`,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f9.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme19: {
    bg: Images.bg19,
    ft: Images.ft19,
    fb: Images.fb19,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/5 w-1/2`,
    fbStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/5 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-red-300 space-y-8 md:space-y-8`,
    tStyle: `text-center text-red-300 p-10 md:px-20 md:py-10 border border-red-300`,
    xTopStyle: -100,
    xBottomStyle: 100,
    textColor: `text-red-300`,
    themeColor: 'bg-gray-800',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme20: {
    bg: Images.bg20,
    ft: '',
    fb: '',
    fc: Images.fc20,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-52 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-blue-100',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme21: {
    bg: Images.bg21,
    ft: Images.ft21,
    fb: Images.fb21,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-2/5 w-4/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/6 w-1/3`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme22: {
    bg: Images.bg22,
    ft: '',
    fb: '',
    fc: Images.fc22,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-40 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-red-100',
    cd1: "bg-red-200 p-4 rounded-lg",
    cd2: "bg-red-300 p-4 rounded-lg",
    cd3: "bg-red-400 p-4 rounded-lg",
    cd4: "bg-red-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme23: {
    bg: Images.bg15,
    ft: Images.ft23,
    fb: Images.fb23,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-1/6 w-2/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/6 w-2/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-blue-100',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme24: {
    bg: Images.bg24,
    ft: Images.ft24,
    fb: Images.fb24,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-1/2 w-3/4`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/6 w-2/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme25: {
    bg: Images.bg25,
    ft: Images.ft25,
    fb: Images.fb25,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-1/5 w-2/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/6 w-2/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-purple-100',
    cd1: "bg-purple-200 p-4 rounded-lg",
    cd2: "bg-purple-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme26: {
    bg: Images.bg26,
    ft: Images.ft26,
    fb: Images.fb26,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-1/4 w-3/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/4 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-white',
    cd1: "bg-purple-200 p-4 rounded-lg",
    cd2: "bg-purple-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme27: {
    bg: Images.bg27,
    ft: Images.ft27,
    fb: Images.fb27,
    fc: '',
    ftStyle: `absolute top-0 right-0 md:w-1/6 sm:w-1/2 w-1/3`,
    fbStyle: `absolute bottom-0 left-0 md:w-1/5 sm:w-1/2 w-1/2`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-purple-100',
    cd1: "bg-purple-200 p-4 rounded-lg",
    cd2: "bg-purple-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme28: {
    bg: Images.bg28,
    ft: '',
    fb: '',
    fc: Images.fc28,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-40 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-[#dfdad7]',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme29: {
    bg: Images.bg29,
    ft: Images.ft29,
    fb: Images.fb29,
    fc: Images.fc29,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-3/5`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme30: {
    bg: Images.bg30,
    ft: Images.ft30,
    fb: Images.fb30,
    fc: Images.fc30,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/5 w-2/5`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/5 w-2/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme31: {
    bg: Images.bg31,
    ft: '',
    fb: '',
    fc: Images.fc31,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-40 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-purple-100',
    cd1: "bg-purple-200 p-4 rounded-lg",
    cd2: "bg-purple-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme32: {
    bg: Images.bg32,
    ft: Images.ft32,
    fb: Images.fb32,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-purple-100',
    cd1: "bg-purple-200 p-4 rounded-lg",
    cd2: "bg-purple-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme33: {
    bg: Images.bg33,
    ft: Images.ft33,
    fb: Images.fb33,
    fc: '',
    ftStyle: `absolute top-0 right-0 md:w-1/4 sm:w-1/2 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 md:w-1/4 sm:w-1/2 w-1/2`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme34: {
    bg: Images.bg34,
    ft: '',
    fb: '',
    fc: Images.fc34,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-48 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-red-100',
    cd1: "bg-red-200 p-4 rounded-lg",
    cd2: "bg-red-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme35: {
    bg: Images.bg35,
    ft: Images.ft35,
    fb: Images.fb35,
    fc: '',
    ftStyle: `absolute top-0 right-0 md:w-1/2 sm:w-3/4 w-full`,
    fbStyle: `absolute bottom-0 left-0 md:w-1/2 sm:w-3/4 w-full`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme36: {
    bg: Images.bg36,
    ft: Images.ft36,
    fb: Images.fb36,
    fc: '',
    ftStyle: `absolute top-0 left-0 md:w-1/3 sm:w-3/4 w-3/4`,
    fbStyle: `absolute bottom-0 right-0 md:w-1/3 sm:w-3/4 w-3/4`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme37: {
    bg: Images.bg37,
    ft: Images.ft37,
    fb: Images.fb37,
    fc: '',
    ftStyle: `absolute top-0 right-0 md:w-1/2 sm:w-3/4 w-full`,
    fbStyle: `absolute bottom-0 left-0 md:w-1/2 sm:w-3/4 w-full`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-neutral-50 space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-white`,
    xTopStyle: 100,
    xBottomStyle: -100,
    textColor: `text-neutral-50`,
    themeColor: 'bg-[#805f8d]',
    cd1: "bg-red-200 p-4 rounded-lg",
    cd2: "bg-red-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme38: {
    bg: Images.bg38,
    ft: Images.ft38,
    fb: Images.fb38,
    fc: Images.fc38,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/5 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/5 w-1/2`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-neutral-50 space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-neutral-50`,
    themeColor: 'bg-[#412649]',
    cd1: "bg-secondary-500 p-4 rounded-lg",
    cd2: "bg-secondary-700 p-4 rounded-lg",
    cd3: "bg-purple-600 p-4 rounded-lg",
    cd4: "bg-purple-700 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme39: {
    bg: Images.bg39,
    ft: Images.ft39,
    fb: Images.fb39,
    fc: Images.fc39,
    ftStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/5 w-1/3`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/5 w-1/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-purple-100',
    cd1: "bg-purple-200 p-4 rounded-lg",
    cd2: "bg-purple-300 p-4 rounded-lg",
    cd3: "bg-secondary-500 p-4 rounded-lg",
    cd4: "bg-secondary-700 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme40: {
    bg: Images.bg40,
    ft: Images.ft40,
    fb: Images.fb40,
    fc: Images.fc40,
    ftStyle: `absolute top-50 left-0 sm:w-1/5 md:w-1/6 w-1/5`,
    fbStyle: `absolute top-50 right-0 sm:w-1/5 md:w-1/6 w-1/5`,
    fcStyle: `text-center p-28 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-8 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    themeColor: 'bg-[#efe9e7]',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme41: {
    bg: Images.bg41,
    ft: Images.ft41,
    fb: Images.fb41,
    fc: Images.fc41,
    ftStyle: `absolute top-0 right-0 sm:w-1/2 md:w-1/4 w-2/3`,
    fbStyle: `absolute bottom-0 left-0 sm:w-1/2 md:w-1/4 w-2/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full h-screen flex flex-col justify-center text-green-50 space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-green-50`,
    textColor: `text-green-600`,
    themeColor: 'bg-green-100',
    cd1: "bg-green-200 p-4 rounded-lg",
    cd2: "bg-green-300 p-4 rounded-lg",
    cd3: "bg-green-400 p-4 rounded-lg",
    cd4: "bg-green-500 p-4 rounded-lg",
    H2: Fonts.f18.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme42: {
    bg: Images.bg42,
    ft: Images.ft42,
    fb: Images.fb42,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-auto md:w-1/5 sm:h-screen w-2/3`,
    fbStyle: `absolute bottom-0 right-0 sm:w-auto md:w-1/5 sm:h-screen w-2/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full h-screen flex flex-col justify-center text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme43: {
    bg: Images.bg43,
    ft: '',
    fb: '',
    fc: Images.fc43,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-48 sm:space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-[#efedec]',
    cd1: "bg-neutral-100 p-4 rounded-lg",
    cd2: "bg-neutral-200 p-4 rounded-lg",
    cd3: "bg-neutral-300 p-4 rounded-lg",
    cd4: "bg-neutral-400 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme44: {
    bg: Images.bg44,
    ft: Images.ft44,
    fb: Images.fb44,
    fc: Images.fc44,
    ftStyle: `absolute top-0 left-0 sm:w-1/2 md:w-1/3 w-4/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-1/2 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-100',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme45: {
    bg: Images.bg45,
    ft: Images.ft45,
    fb: Images.fb45,
    fc: Images.fc45,
    ftStyle: `absolute top-50 left-0 w-auto h-1/2 sm:h-screen`,
    fbStyle: `absolute bottom-50 right-0 w-auto h-1/2 sm:h-screen`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-8 h-full sm:h-5/6 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-100',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme46: {
    bg: Images.bg46,
    ft: Images.ft46,
    fb: Images.fb46,
    fc: '',
    ftStyle: `absolute top-50 left-0 w-auto h-1/2 sm:h-screen`,
    fbStyle: `absolute bottom-50 right-0 w-auto h-1/2 sm:h-screen`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-white',
    cd1: "bg-stone-100 p-4 rounded-lg",
    cd2: "bg-stone-200 p-4 rounded-lg",
    cd3: "bg-stone-300 p-4 rounded-lg",
    cd4: "bg-stone-400 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme47: {
    bg: Images.bg47,
    ft: Images.ft47,
    fb: Images.fb47,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/2 w-4/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/3 w-2/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-100',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme48: {
    bg: Images.bg48,
    ft: Images.ft48,
    fb: Images.fb48,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-1/2 md:w-1/3 w-2/3`,
    fbStyle: `absolute bottom-0 right-0 sm:w-1/2 md:w-1/3 w-2/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-100',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme49: {
    bg: Images.bg49,
    ft: '',
    fb: '',
    fc: Images.fc49,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-48 md:space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-neutral-100',
    cd1: "bg-neutral-100 p-4 rounded-lg",
    cd2: "bg-neutral-200 p-4 rounded-lg",
    cd3: "bg-neutral-300 p-4 rounded-lg",
    cd4: "bg-neutral-400 p-4 rounded-lg",
    H2: Fonts.f18.H2,
    H2size: "text-6xl",
    fontP: Fonts.f18.fontP,
  },
  theme50: {
    bg: '',
    ft: Images.ft50,
    fb: Images.fb50,
    fc: Images.fc50,
    ftStyle: `absolute top-50 left-0 sm:w-auto md:w-1/5 sm:h-screen w-1/2`,
    fbStyle: `absolute bottom-50 right-0 sm:w-auto md:w-1/5 sm:h-screen w-1/2`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full sm:h-screen flex flex-col justify-center text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-pink-100',
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme51: {
    bg: Images.bg51,
    ft: Images.ft51,
    fb: Images.fb51,
    fc: Images.fc51,
    ftStyle: `absolute top-0 left-0 w-auto h-1/2 sm:h-screen`,
    fbStyle: `absolute bottom-0 right-0 w-auto h-2/5 sm:h-screen`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-4/5 text-black space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-100',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme52: {
    bg: Images.bg52,
    ft: Images.ft52,
    fb: Images.fb52,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-1/3 md:w-1/5 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-1/3 md:w-1/5 w-1/2`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-gray-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme53: {
    bg: Images.bg53,
    ft: Images.ft53,
    fb: Images.fb53,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-1/3 md:w-1/5 w-2/3`,
    fbStyle: `absolute bottom-0 right-0 sm:w-1/3 md:w-1/5 w-2/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme54: {
    bg: Images.bg54,
    ft: Images.ft54,
    fb: Images.fb54,
    fc: Images.fc54,
    ftStyle: `absolute top-0 right-0 w-3/4 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-3/4 sm:w-1/2 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-28 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme55: {
    bg: Images.bg55,
    ft: Images.ft55,
    fb: Images.fb55,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/2 w-full`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/2 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme56: {
    bg: '',
    ft: Images.ft56,
    fb: Images.fb56,
    fc: '',
    ftStyle: `absolute top-0 left-0 w-4/5 sm:w-auto sm:h-screen`,
    fbStyle: `absolute bottom-0 right-0 w-4/5 sm:w-auto sm:h-screen`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-blue-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme57: {
    bg: Images.bg57,
    ft: '',
    fb: '',
    fc: Images.fc57,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-full w-full text-black space-y-48 sm:space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-pink-500`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-neutral-50',
    cd1: "bg-pink-100 p-4 rounded-lg",
    cd2: "bg-pink-200 p-4 rounded-lg",
    cd3: "bg-pink-300 p-4 rounded-lg",
    cd4: "bg-pink-400 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme58: {
    bg: Images.bg58,
    ft: Images.ft58,
    fb: Images.fb58,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:h-screen w-3/4 sm:w-auto`,
    fbStyle: `absolute bottom-0 right-0 sm:h-screen w-1/2 sm:w-auto`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-blue-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme59: {
    bg: Images.bg59,
    ft: Images.ft59,
    fb: Images.fb59,
    fc: Images.fc59,
    ftStyle: `absolute top-0 left-0 w-3/4 sm:w-1/2 md:w-1/2`,
    fbStyle: `absolute bottom-0 right-0 w-3/4 sm:w-1/2 md:w-1/2`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-4/5 text-black space-y-28 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-stone-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-stone-100',
    cd1: "bg-stone-200 p-4 rounded-lg",
    cd2: "bg-stone-300 p-4 rounded-lg",
    cd3: "bg-stone-400 p-4 rounded-lg",
    cd4: "bg-stone-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme60: {
    bg: Images.bg60,
    ft: Images.ft60,
    fb: Images.fb60,
    fc: Images.fc60,
    ftStyle: `absolute top-0 right-0 w-3/5 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-3/5 sm:w-1/2 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-28 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-stone-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme61: {
    bg: Images.bg61,
    ft: Images.ft61,
    fb: Images.fb61,
    fc: Images.fc61,
    ftStyle: `absolute top-0 left-0 w-3/5 sm:w-1/2 md:w-auto md:h-screen`,
    fbStyle: `absolute bottom-0 right-0 w-3/5 sm:w-1/2 md:w-auto md:h-screen`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-28 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-neutral-200`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme62: {
    bg: Images.bg62,
    ft: Images.ft62,
    fb: Images.fb62,
    fc: '',
    ftStyle: `absolute top-0 left-0 h-1/2 sm:h-2/3 sm:w-auto`,
    fbStyle: `absolute bottom-0 right-0 h-1/2 sm:h-2/3 sm:w-auto`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme63: {
    bg: Images.bg63,
    ft: Images.ft63,
    fb: Images.fb63,
    fc: '',
    ftStyle: `absolute top-0 left-0 h-1/3 sm:h-1/2 sm:w-auto`,
    fbStyle: `absolute bottom-0 right-0 h-1/3 sm:h-1/2 sm:w-auto`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme64: {
    bg: '',
    ft: Images.ft64,
    fb: Images.fb64,
    fc: Images.fc64,
    ftStyle: `absolute top-0 right-0 w-2/3 sm:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-2/3 sm:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full sm:h-screen flex flex-col justify-center text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-white',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme65: {
    bg: Images.bg65,
    ft: '',
    fb: '',
    fc: Images.fc65,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-full w-full text-black space-y-48 sm:space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-pink-500`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-pink-50',
    cd1: "bg-pink-100 p-4 rounded-lg",
    cd2: "bg-pink-200 p-4 rounded-lg",
    cd3: "bg-pink-300 p-4 rounded-lg",
    cd4: "bg-pink-400 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme66: {
    bg: Images.bg66,
    ft: Images.ft66,
    fb: Images.fb66,
    fc: Images.fc66,
    ftStyle: `absolute top-0 right-0 w-3/5 sm:w-1/2 md:w-auto md:h-screen`,
    fbStyle: `absolute bottom-0 left-0 w-3/5 sm:w-1/2 md:w-auto md:h-screen`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-28 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-rose-500`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-rose-50',
    cd1: "bg-rose-200 p-4 rounded-lg",
    cd2: "bg-rose-300 p-4 rounded-lg",
    cd3: "bg-rose-400 p-4 rounded-lg",
    cd4: "bg-rose-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme67: {
    bg: Images.bg67,
    ft: Images.ft67,
    fb: Images.fb67,
    fc: Images.fc67,
    ftStyle: `absolute top-0 left-0 w-full sm:w-3/4 md:w-3/5`,
    fbStyle: `absolute bottom-0 right-0 w-full sm:w-3/4 md:w-3/5`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-28 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-orange-500`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-stone-100',
    cd1: "bg-orange-200 p-4 rounded-lg",
    cd2: "bg-orange-300 p-4 rounded-lg",
    cd3: "bg-orange-400 p-4 rounded-lg",
    cd4: "bg-orange-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme68: {
    bg: Images.bg68,
    ft: Images.ft68,
    fb: Images.fb68,
    fc: Images.fc68,
    ftStyle: `absolute top-0 right-0 w-3/4 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-3/4 sm:w-1/2 md:w-1/3`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-28 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-neutral-500`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-100',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme69: {
    bg: Images.bg69,
    ft: Images.ft69,
    fb: Images.fb69,
    fc: Images.fc69,
    ftStyle: `absolute top-0 left-0 w-2/5 sm:w-1/4 md:w-auto md:h-3/4`,
    fbStyle: `absolute bottom-0 right-0 w-2/5 sm:w-1/4 md:w-auto md:h-3/4`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-32 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-neutral-500`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme70: {
    bg: Images.bg70,
    ft: Images.ft70,
    fb: Images.fb70,
    fc: '',
    ftStyle: `absolute top-28 sm:top-5 left-50 sm:w-2/3 md:w-1/2`,
    fbStyle: `absolute bottom-28 sm:bottom-5 right-50 sm:w-2/3 md:w-1/2`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-neutral-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme71: {
    bg: Images.bg71,
    ft: Images.ft71,
    fb: Images.fb71,
    fc: Images.fc71,
    ftStyle: `absolute top-0 right-0 w-1/2 sm:w-1/3 md:w-auto md:h-3/4`,
    fbStyle: `absolute bottom-0 left-0 w-1/2 sm:w-1/3 md:w-auto md:h-3/4`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-black space-y-32 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-stone-500`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-stone-100',
    cd1: "bg-stone-200 p-4 rounded-lg",
    cd2: "bg-stone-300 p-4 rounded-lg",
    cd3: "bg-stone-400 p-4 rounded-lg",
    cd4: "bg-stone-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme72: {
    bg: Images.bg72,
    ft: Images.ft72,
    fb: Images.fb72,
    fc: Images.fc72,
    ftStyle: `absolute top-0 left-0 w-3/4 sm:w-1/2 md:w-auto md:h-1/2`,
    fbStyle: `absolute bottom-0 right-0 w-3/4 sm:w-1/2 md:w-auto md:h-1/2`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-3/4 text-neutral-100 space-y-8 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-neutral-500`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-neutral-100`,
    textColor: `text-neutral-100`,
    themeColor: 'bg-neutral-700',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme73: {
    bg: Images.bg73,
    ft: Images.ft73,
    fb: Images.fb73,
    fc: Images.fc73,
    ftStyle: `absolute top-0 left-0 w-3/4 sm:w-auto sm:h-4/5`,
    fbStyle: `absolute bottom-0 right-0 w-3/4 sm:w-auto sm:h-4/5`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-3/4 text-neutral-100 space-y-8 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-neutral-500`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-neutral-100`,
    textColor: `text-neutral-100`,
    themeColor: 'bg-neutral-600',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-5xl",
    fontP: Fonts.f7.fontP,
  },
  theme74: {
    bg: Images.bg74,
    ft: Images.ft74,
    fb: '',
    fc: Images.fc74,
    ftStyle: `absolute top-0 right-0 w-3/4 sm:w-3/5 md:w-1/2`,
    fbStyle: `absolute bottom-0 left-0 w-3/4 sm:w-1/2`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-emerald-300 space-y-8 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-neutral-500`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-emerald-300`,
    textColor: `text-emerald-300`,
    themeColor: 'bg-neutral-700',
    cd1: "bg-neutral-200 p-4 rounded-lg",
    cd2: "bg-neutral-300 p-4 rounded-lg",
    cd3: "bg-neutral-400 p-4 rounded-lg",
    cd4: "bg-neutral-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme75: {
    bg: Images.bg75,
    ft: Images.ft75,
    fb: Images.fb75,
    fc: Images.fc75,
    ftStyle: `absolute top-0 left-0 w-3/4 sm:w-2/5 md:w-1/4`,
    fbStyle: `absolute bottom-0 right-0 w-3/4 sm:w-2/5 md:w-1/4`,
    fcStyle: `z-10 text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-yellow-200 space-y-8 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-500`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-yellow-200`,
    textColor: `text-yellow-200`,
    themeColor: 'bg-neutral-800',
    cd1: "bg-yellow-50 p-4 rounded-lg",
    cd2: "bg-yellow-100 p-4 rounded-lg",
    cd3: "bg-yellow-200 p-4 rounded-lg",
    cd4: "bg-yellow-300 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme76: {
    bg: Images.bg76,
    ft: Images.ft76,
    fb: Images.fb76,
    fc: Images.fc76,
    ftStyle: `absolute top-0 right-0 w-3/4 sm:w-2/5 md:w-1/4`,
    fbStyle: `absolute bottom-0 left-0 w-3/4 sm:w-2/5 md:w-1/4`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-full text-yellow-200 space-y-8 sm:space-y-8 h-full sm:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-500`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-yellow-200`,
    textColor: `text-yellow-200`,
    themeColor: 'bg-neutral-800',
    cd1: "bg-yellow-50 p-4 rounded-lg",
    cd2: "bg-yellow-100 p-4 rounded-lg",
    cd3: "bg-yellow-200 p-4 rounded-lg",
    cd4: "bg-yellow-300 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme77: {
    bg: Images.bg77,
    ft: Images.ft77,
    fb: Images.fb77,
    fc: '',
    ftStyle: `absolute top-0 left-50 sm:w-full md:-top-32 md:w-full`,
    fbStyle: `absolute bottom-0 right-50 sm:w-full md:w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-black space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-gray-50',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme78: {
    bg: Images.bg78,
    ft: Images.ft78,
    fb: Images.fb78,
    fc: Images.fc78,
    ftStyle: `absolute top-0 right-50 sm:w-full md:-top-32 md:w-full`,
    fbStyle: `absolute bottom-0 left-50 sm:w-full md:-bottom-40 md:w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-2/3 w-5/6 text-yellow-100 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-600`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-yellow-100`,
    textColor: `text-yellow-100`,
    themeColor: 'bg-neutral-800',
    cd1: "bg-yellow-200 p-4 rounded-lg",
    cd2: "bg-yellow-300 p-4 rounded-lg",
    cd3: "bg-yellow-400 p-4 rounded-lg",
    cd4: "bg-yellow-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme79: {
    bg: Images.bg79,
    ft: Images.ft79,
    fb: Images.fb79,
    fc: Images.fc79,
    ftStyle: `absolute top-0 left-0 w-1/2 sm:w-2/5 md:w-1/3`,
    fbStyle: `absolute top-0 right-0 w-1/2 sm:w-2/5 md:w-1/3`,
    fcStyle: `text-center p-20 pb-32 md:p-44 w-full md:w-2/3 sm:w-2/3 text-black space-y-8 h-full flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme80: {
    bg: Images.bg80,
    ft: '',
    fb: '',
    fc: Images.fc80,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-full w-full text-black space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-amber-500`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-stone-100',
    cd1: "bg-orange-100 p-4 rounded-lg",
    cd2: "bg-orange-200 p-4 rounded-lg",
    cd3: "bg-orange-300 p-4 rounded-lg",
    cd4: "bg-orange-400 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-5xl",
    fontP: Fonts.f13.fontP,
  },
  theme81: {
    bg: Images.bg81,
    ft: Images.ft81,
    fb: Images.fb81,
    fc: Images.fc81,
    ftStyle: `absolute top-0 right-0 w-3/4 sm:w-2/5 md:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-3/4 sm:w-2/5 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-full md:w-1/2 sm:w-2/3 text-yellow-100 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-600`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-yellow-100`,
    textColor: `text-yellow-100`,
    themeColor: 'bg-neutral-900',
    cd1: "bg-yellow-200 p-4 rounded-lg",
    cd2: "bg-yellow-300 p-4 rounded-lg",
    cd3: "bg-yellow-400 p-4 rounded-lg",
    cd4: "bg-yellow-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme82: {
    bg: Images.bg82,
    ft: Images.ft82,
    fb: Images.fb82,
    fc: Images.fc82,
    ftStyle: `absolute top-0 left-0 w-full sm:w-3/5 md:w-1/3`,
    fbStyle: `absolute bottom-0 right-0 w-full sm:w-3/5 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-5/6 sm:w-1/2 md:w-1/2 text-yellow-100 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-yellow-100`,
    textColor: `text-yellow-100`,
    themeColor: 'bg-neutral-900',
    cd1: "bg-yellow-200 p-4 rounded-lg",
    cd2: "bg-yellow-300 p-4 rounded-lg",
    cd3: "bg-yellow-400 p-4 rounded-lg",
    cd4: "bg-yellow-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme83: {
    bg: Images.bg83,
    ft: Images.ft83,
    fb: Images.fb83,
    fc: '',
    ftStyle: `absolute top-0 left-0 w-1/2 sm:w-1/3 md:w-1/3`,
    fbStyle: `absolute top-0 right-0 w-1/2 sm:w-1/3 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-5/6 sm:w-1/2 md:w-1/2 text-yellow-100 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-yellow-100`,
    textColor: `text-yellow-100`,
    themeColor: 'bg-slate-700',
    cd1: "bg-yellow-100 p-4 rounded-lg",
    cd2: "bg-yellow-200 p-4 rounded-lg",
    cd3: "bg-yellow-300 p-4 rounded-lg",
    cd4: "bg-yellow-400 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme84: {
    bg: Images.bg84,
    ft: Images.ft84,
    fb: Images.fb84,
    fc: '',
    ftStyle: `absolute top-0 left-0 w-2/3 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute bottom-0 right-0 w-2/3 sm:w-1/2 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-5/6 sm:w-1/2 md:w-1/2 text-gray-800 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-600`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-gray-800`,
    textColor: `text-gray-800`,
    themeColor: 'bg-gray-100',
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme85: {
    bg: Images.bg85,
    ft: Images.ft85,
    fb: Images.fb85,
    fc: '',
    ftStyle: `absolute top-0 right-0 w-full sm:w-2/3 md:w-2/5`,
    fbStyle: `absolute bottom-0 left-0 w-full sm:w-2/3 md:w-2/5`,
    fcStyle: `text-center p-20 md:p-44 w-5/6 sm:w-1/2 md:w-1/2 text-yellow-100 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-100`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-yellow-100`,
    textColor: `text-yellow-100`,
    themeColor: 'bg-slate-900',
    cd1: "bg-yellow-100 p-4 rounded-lg",
    cd2: "bg-yellow-200 p-4 rounded-lg",
    cd3: "bg-yellow-300 p-4 rounded-lg",
    cd4: "bg-yellow-400 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme86: {
    bg: Images.bg86,
    ft: Images.ft86,
    fb: Images.fb86,
    fc: Images.fc86,
    ftStyle: `absolute top-0 right-0 w-full sm:w-3/5 md:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-full sm:w-3/5 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-5/6 sm:w-1/2 md:w-1/2 text-slate-800 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-slate-800`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-slate-800`,
    textColor: `text-slate-800`,
    themeColor: 'bg-slate-100',
    cd1: "bg-slate-200 p-4 rounded-lg",
    cd2: "bg-slate-300 p-4 rounded-lg",
    cd3: "bg-slate-400 p-4 rounded-lg",
    cd4: "bg-slate-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme87: {
    bg: Images.bg87,
    ft: Images.ft87,
    fb: Images.fb87,
    fc: Images.fc87,
    ftStyle: `absolute top-0 right-0 w-full sm:w-3/5 md:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-full sm:w-3/5 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-5/6 sm:w-1/2 md:w-1/2 text-slate-800 space-y-8 h-full md:h-4/5 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-slate-800`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-slate-800`,
    textColor: `text-slate-800`,
    themeColor: 'bg-slate-100',
    cd1: "bg-slate-200 p-4 rounded-lg",
    cd2: "bg-slate-300 p-4 rounded-lg",
    cd3: "bg-slate-400 p-4 rounded-lg",
    cd4: "bg-slate-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme88: {
    bg: Images.bg88,
    ft: '',
    fb: '',
    fc: Images.fc88,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-full w-full text-blue-100 space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-slate-700`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-stone-100',
    cd1: "bg-slate-100 p-4 rounded-lg",
    cd2: "bg-slate-200 p-4 rounded-lg",
    cd3: "bg-slate-300 p-4 rounded-lg",
    cd4: "bg-slate-400 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme89: {
    bg: Images.bg89,
    ft: Images.ft89,
    fb: Images.fb89,
    fc: Images.fc89,
    ftStyle: `absolute top-0 left-0 w-1/2 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute top-0 right-0 w-1/2 sm:w-1/2 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-full sm:w-3/4 md:w-auto text-slate-800 space-y-8 h-full md:h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-slate-800`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-slate-800`,
    textColor: `text-slate-800`,
    themeColor: 'bg-slate-200',
    cd1: "bg-slate-200 p-4 rounded-lg",
    cd2: "bg-slate-300 p-4 rounded-lg",
    cd3: "bg-slate-400 p-4 rounded-lg",
    cd4: "bg-slate-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme90: {
    bg: Images.bg90,
    ft: Images.ft90,
    fb: Images.fb90,
    fc: Images.fc90,
    ftStyle: `absolute top-0 left-0 w-1/2 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute top-0 right-0 w-1/2 sm:w-1/2 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-full sm:w-3/4 md:w-auto text-slate-800 space-y-8 h-full md:h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-slate-800`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-slate-800`,
    textColor: `text-slate-800`,
    themeColor: 'bg-blue-100',
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme91: {
    bg: Images.bg91,
    ft: Images.ft91,
    fb: Images.fb91,
    fc: '',
    ftStyle: `absolute bottom-0 left-0 w-1/2 sm:w-1/3 md:w-1/3`,
    fbStyle: `absolute bottom-0 right-0 w-1/2 sm:w-1/3 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-full sm:w-3/4 md:w-auto text-yellow-200 space-y-8 h-full md:h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-200`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-yellow-200`,
    textColor: `text-yellow-200`,
    themeColor: 'bg-slate-700',
    cd1: "bg-yellow-200 p-4 rounded-lg",
    cd2: "bg-yellow-300 p-4 rounded-lg",
    cd3: "bg-yellow-400 p-4 rounded-lg",
    cd4: "bg-yellow-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme92: {
    bg: Images.bg92,
    ft: Images.ft92,
    fb: Images.fb92,
    fc: Images.fc92,
    ftStyle: `absolute top-0 left-50 w-full md:-top-40`,
    fbStyle: `absolute bottom-0 right-50 w-full md:-bottom-40`,
    fcStyle: `text-center p-20 md:p-44 w-full sm:w-3/4 md:w-auto text-stone-700 space-y-8 h-full md:h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-stone-700`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-stone-700`,
    textColor: `text-stone-700`,
    themeColor: 'bg-stone-100',
    cd1: "bg-stone-200 p-4 rounded-lg",
    cd2: "bg-stone-300 p-4 rounded-lg",
    cd3: "bg-stone-400 p-4 rounded-lg",
    cd4: "bg-stone-500 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-6xl",
    fontP: Fonts.f1.fontP,
  },
  theme93: {
    bg: Images.bg93,
    ft: Images.ft93,
    fb: Images.fb93,
    fc: '',
    ftStyle: `absolute top-0 left-50 w-full sm:-top-8 md:-top-32`,
    fbStyle: `absolute bottom-0 right-50 w-full sm:-bottom-12 md:-bottom-52`,
    fcStyle: `text-center p-20 md:p-44 w-full sm:w-3/4 md:w-auto text-yellow-200 space-y-8 h-full md:h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-200`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-yellow-200`,
    textColor: `text-yellow-200`,
    themeColor: 'bg-slate-900',
    cd1: "bg-yellow-200 p-4 rounded-lg",
    cd2: "bg-yellow-300 p-4 rounded-lg",
    cd3: "bg-yellow-400 p-4 rounded-lg",
    cd4: "bg-yellow-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-6xl",
    fontP: Fonts.f13.fontP,
  },
  theme94: {
    bg: Images.bg94,
    ft: Images.ft94,
    fb: Images.fb94,
    fc: Images.fc94,
    ftStyle: `absolute top-0 left-0 w-2/3 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute bottom-0 right-0 w-2/3 sm:w-1/2 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-5/6 sm:w-3/5 h-full text-yellow-100 space-y-8 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-yellow-100`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-yellow-100`,
    textColor: `text-yellow-100`,
    themeColor: 'bg-emerald-900',
    cd1: "bg-emerald-200 p-4 rounded-lg",
    cd2: "bg-emerald-300 p-4 rounded-lg",
    cd3: "bg-emerald-400 p-4 rounded-lg",
    cd4: "bg-emerald-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme95: {
    bg: Images.bg95,
    ft: '',
    fb: '',
    fc: Images.fc95,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-44 md:w-1/2 sm:w-full w-full text-neutral-100 space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-black`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-gray-50',
    cd1: "bg-pink-100 p-4 rounded-lg",
    cd2: "bg-pink-200 p-4 rounded-lg",
    cd3: "bg-pink-300 p-4 rounded-lg",
    cd4: "bg-pink-400 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme96: {
    bg: '',
    ft: '',
    fb: '',
    fc: Images.fc96,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center pr-32 p-20 md:pr-56 md:p-44 w-full md:w-1/2 text-black space-y-8 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-black`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-rose-50',
    cd1: "bg-orange-100 p-4 rounded-lg",
    cd2: "bg-orange-200 p-4 rounded-lg",
    cd3: "bg-orange-300 p-4 rounded-lg",
    cd4: "bg-orange-400 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-5xl",
    fontP: Fonts.f2.fontP,
  },
  theme97: {
    bg: Images.bg97,
    ft: '',
    fb: '',
    fc: Images.fc97,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 pb-32 md:p-44 w-full md:w-1/2 text-black space-y-4 h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-black`,
    xTopStyle: '',
    xBottomStyle: '',
    themeColor: 'bg-neutral-100',
    cd1: "bg-yellow-100 p-4 rounded-lg",
    cd2: "bg-yellow-200 p-4 rounded-lg",
    cd3: "bg-yellow-300 p-4 rounded-lg",
    cd4: "bg-yellow-400 p-4 rounded-lg",
    H2: Fonts.f1.H2,
    H2size: "text-5xl",
    fontP: Fonts.f1.fontP,
  },
  theme98: {
    bg: Images.bg98,
    ft: Images.ft98,
    fb: Images.fb98,
    fc: '',
    ftStyle: `absolute top-0 right-0 w-3/5 sm:w-1/2 md:w-1/3`,
    fbStyle: `absolute bottom-0 left-0 w-3/5 sm:w-1/2 md:w-1/3`,
    fcStyle: `text-center p-20 md:p-44 w-full sm:w-3/4 md:w-auto text-emerald-800 space-y-8 h-full md:h-screen flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-emerald-800`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-emerald-800`,
    textColor: `text-emerald-800`,
    themeColor: 'bg-neutral-50',
    cd1: "bg-emerald-200 p-4 rounded-lg",
    cd2: "bg-emerald-300 p-4 rounded-lg",
    cd3: "bg-emerald-400 p-4 rounded-lg",
    cd4: "bg-emerald-500 p-4 rounded-lg",
    H2: Fonts.f2.H2,
    H2size: "text-6xl",
    fontP: Fonts.f2.fontP,
  },
  theme99: {
    bg: Images.bg99,
    ft: Images.ft99,
    fb: Images.fb99,
    fc: '',
    ftStyle: `absolute top-0 right-0 w-full sm:-top-20 md:w-1/2`,
    fbStyle: `absolute bottom-0 left-0 w-2/3 sm:w-2/5 md:w-1/3`,
    fcStyle: `z-10 text-center p-20 md:p-44 w-full sm:w-3/4 md:w-auto text-black space-y-8 h-full md:h-screen flex flex-col justify-center`,
    tStyle: `text-center p-20 md:px-20 md:py-10 border border-black`,
    xTopStyle: 100,
    xBottomStyle: -100,
    centerText: `text-black`,
    textColor: `text-black`,
    themeColor: 'bg-stone-100',
    cd1: "bg-emerald-200 p-4 rounded-lg",
    cd2: "bg-emerald-300 p-4 rounded-lg",
    cd3: "bg-emerald-400 p-4 rounded-lg",
    cd4: "bg-emerald-500 p-4 rounded-lg",
    H2: Fonts.f7.H2,
    H2size: "text-6xl",
    fontP: Fonts.f7.fontP,
  },
  theme100: {
    bg: '',
    ft: Images.ft100,
    fb: Images.fb100,
    fc: Images.fc100,
    ftStyle: `absolute top-0 left-50 w-full sm:-top-32 md:-top-60`,
    fbStyle: `absolute bottom-0 right-50 w-full sm:-bottom-32 md:-bottom-60`,
    fcStyle: `text-center p-20 md:p-44 w-full sm:w-full text-stone-100 space-y-16 sm:space-y-28 md:space-y-8 flex flex-col justify-center`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-stone-100`,
    xTopStyle: -100,
    xBottomStyle: 100,
    centerText: `text-stone-100`,
    textColor: `text-stone-100`,
    themeColor: 'bg-stone-900',
    cd1: "bg-stone-200 p-4 rounded-lg",
    cd2: "bg-stone-300 p-4 rounded-lg",
    cd3: "bg-stone-400 p-4 rounded-lg",
    cd4: "bg-stone-500 p-4 rounded-lg",
    H2: Fonts.f13.H2,
    H2size: "text-4xl sm:text-6xl",
    fontP: Fonts.f13.fontP,
  },
};

export default themes;
