import React  from 'react';
import { FaWaze, FaGoogle } from 'react-icons/fa';

function LiveLocation({ directions }) {

  const storedLocations = JSON.parse(directions);

  return (
    <div className="relative">
      {storedLocations.length > 0 && (
        <div className="border p-2 my-2 rounded flex items-center justify-between">
          <p>{storedLocations[0].locationName}</p>
          <div className="flex space-x-2">
            <a
              className="flex items-center justify-center w-12 h-12 rounded-full bg-secondary-700 text-neutral-50 text-2xl"
              href={`${storedLocations[0].googleMapsUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGoogle />
            </a>
            <a
              className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-300 text-black text-2xl"
              href={`${storedLocations[0].wazeUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWaze />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveLocation;
