import React, { useState } from 'react';
import { FaFont } from 'react-icons/fa';
import LoadingSpinner from '../LoadingSpinner';

function EditFAB({ setUpdatedH2, setUpdatedFontP }) {
  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSelectVisibility = () => {
    setIsSelectVisible(!isSelectVisible);
  };

  const fontSettings = {
    fonts1: {
      H2: 'font-great-vibes',
      fontP: 'font-montserrat',
    },
    fonts2: {
      H2: 'font-dancing-script',
      fontP: 'font-lato',
    },
    // fonts3: {
    //   H2: 'font-pinyon-script',
    //   fontP: 'font-josefin',
    // },
    // fonts4: {
    //   H2: 'font-monsieur-la-doulaise',
    //   fontP: 'font-playfair-display',
    // },
    // fonts5: {
    //   H2: 'font-mr-dafoe',
    //   fontP: 'font-playfair-display',
    // },
    // fonts6: {
    //   H2: 'font-pacifico',
    //   fontP: 'font-montserrat',
    // },
    fonts7: {
      H2: 'font-playfair-display',
      fontP: 'font-montserrat',
    },
    fonts8: {
      H2: 'font-alex-brush',
      fontP: 'font-montserrat',
    },
    fonts9: {
      H2: 'font-ephesis',
      fontP: 'font-normal',
    },
    // fonts10: {
    //   H2: 'font-herr-von-muellerhoff',
    //   fontP: 'font-montserrat',
    // },
    fonts11: {
      H2: 'font-imperial-script',
      fontP: 'font-montserrat',
    },
    fonts12: {
      H2: 'font-lovers-quarrel',
      fontP: 'font-montserrat',
    },
    fonts13: {
      H2: 'font-monte-carlo',
      fontP: 'font-normal',
    },
    fonts14: {
      H2: 'font-parisienne',
      fontP: 'font-montserrat',
    },
    fonts15: {
      H2: 'font-rosarivo',
      fontP: 'font-montserrat',
    },
    fonts16: {
      H2: 'font-rouge-script',
      fontP: 'font-montserrat',
    },
    fonts17: {
      H2: 'font-style-script',
      fontP: 'font-montserrat',
    },
    fonts18: {
      H2: 'font-tangerine',
      fontP: 'font-lato',
    },
  };

  const handleFontSelection = (e) => {
    const selectedValue = e.target.value;
  
    if (selectedValue === "default") {
      // Remove items from localStorage
      localStorage.removeItem('H2');
      localStorage.removeItem('fontP');
      // Add more items to remove if needed
      setUpdatedH2('');
      setUpdatedFontP('');
      // Reload the page after removing local storage items
      // window.location.reload();
    } else {
      const selectedFonts = fontSettings[selectedValue];
  
      if (selectedFonts) {
        // setIsLoading(true);

        for (const key in selectedFonts) {
          if (selectedFonts.hasOwnProperty(key)) {
            localStorage.setItem(key, selectedFonts[key]);
        
            if (key === 'H2') {
              setUpdatedH2(selectedFonts[key]);
            } else if (key === 'fontP') {
              setUpdatedFontP(selectedFonts[key]);
            }
          }
        }
      }
    }
  
    // Close the select element after a font is selected or "default" is selected
    setIsSelectVisible(false);
  };
  

  return (
    <div className="relative">
      <a
        rel="noopener noreferrer"
        className=" bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 transition duration-300 ease-in-out rounded-full w-12 h-12 flex items-center justify-center shadow-md"
        onClick={toggleSelectVisibility}
      >
        <FaFont className="text-neutral-50 text-xl" />
      </a>

      {isSelectVisible && (
        <div className="fixed bottom-16 right-4 md:right-auto sm:right-auto">
          <div className="relative">
            <div className="flex items-center justify-center">
              <select
                className="block w-40 px-4 py-2 border border-gray-400 rounded-md bg-white appearance-none focus:outline-none focus:shadow-outline"
                onChange={handleFontSelection}
              >
                <option value="" readonly>Select Fonts</option>
                {/* <option value="default" readonly>Default</option> */}
                <option value="fonts1">Great Vibes + Montserrat</option>
                <option value="fonts2">Dancing Script + Lato</option>
                {/* <option value="fonts3">Pinyon Script + Josefin Sans</option> */}
                {/* <option value="fonts4">Monsieur La Doulaise + Playfair</option> */}
                {/* <option value="fonts5">Mr Dafoe + Playfair</option> */}
                {/* <option value="fonts6">Pacifico + Montserrat</option> */}
                <option value="fonts7">Playfair + Montserrat</option>
                <option value="fonts8">Alex Brush + Montserrat</option>
                <option value="fonts9">Ephesis + Montserrat</option>
                {/* <option value="fonts10">Herr Von Muellerhoff + Montserrat</option> */}
                <option value="fonts11">Imperial Script + Montserrat</option>
                <option value="fonts12">Lovers Quarrel + Montserrat</option>
                <option value="fonts13">MonteCarlo + Montserrat</option>
                <option value="fonts14">Parisienne + Montserrat</option>
                <option value="fonts15">Rosarivo + Montserrat</option>
                <option value="fonts16">Rouge Script + Montserrat</option>
                <option value="fonts17">Style Script + Montserrat</option>
                <option value="fonts18">Tangerine + Montserrat</option>

              </select>
              
            </div>
          </div>
        </div>
      )}

      {isLoading && <LoadingSpinner />}
    </div>
  );
}

export default EditFAB;
