import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import logo2 from '../img/logo.png';

function Loading() {
  
  return (
    <div className="fixed inset-0 h-screen w-full bg-white bg-opacity-80 flex flex-col items-center justify-center space-y-5">
      <FaSpinner className="text-4xl text-secondary-500 animate-spin" />
      <img src={logo2} alt="Logo" className="h-12 mb-4" />
    </div>
  );
}

export default Loading;
