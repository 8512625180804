import React, { useState } from "react";
import { FaPhone, FaTimes, FaMapMarkerAlt, FaGift, FaCalendarCheck, FaClock, FaPlus } from "react-icons/fa";
import { motion } from 'framer-motion';

import LiveContacts from "./LiveContacts";
import LiveLocation from "./LiveLocation";
import LiveGift from "./LiveGift";
import LiveDate from "./LiveDate";
import LiveRSVP from "./LiveRSVP";
import LiveSalamKaut from "./LiveSalamKaut";
import GiftIdeas from "../GiftIdeas";

function LiveBottomBar({ gift, contacts, directions, weddingDate, startTime, endTime, fontP, salamKaut, rsvp }) {
  const modalVariants = {
    hidden: { opacity: 0, y: 100 }, // Start hidden and below the view
    visible: { opacity: 1, y: 0 },   // Visible and at original position
    exit: { opacity: 0, y: 100 }      // Exit animation
  };

  const [openModalType, setOpenModalType] = useState(null);
  // const storedGiftState = localStorage.getItem('gift_button');
  // const giftState = storedGiftState ? JSON.parse(storedGiftState) : true;
  // const [giftButton, setGiftButton] = useState(giftState);

  const toggleModal = (modalType) => {
    setOpenModalType((prevModalType) =>
      prevModalType === modalType ? null : modalType
    );
  };

  const handleCloseModal = () => {
    setOpenModalType(null);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred on the overlay (background)
    if (e.target.classList.contains("overlay")) {
      handleCloseModal();
    }
  };

  const giftButton = JSON.parse(gift);
  const [isVisible, setIsVisible] = useState(false);

  const toggleButtons = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <div className="fixed bottom-0 left-0 w-full shadow-md py-2 z-30 bg-white/70">
        <div className="flex items-center justify-between md:justify-center px-3">
          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-3"
            onClick={() => toggleModal("contacts")}
          >
            <FaPhone className="contact text-black text-2xl" />
          </button>

          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-3"
            onClick={() => toggleModal("location")}
          >
            <FaMapMarkerAlt className="contact text-black text-2xl" />
          </button>
          {rsvp ? (
            <button
              rel="noopener noreferrer"
              className="flex items-center justify-center p-3"
              onClick={() => toggleModal("rsvp")}
            >
              <FaCalendarCheck className="contact text-black text-2xl" />
            </button>
          ) : (
            <></>
          )}
            
          {salamKaut ? (
            <button
              className="flex items-center justify-center p-3"
              onClick={() => toggleModal("salamkaut")}
            >
              <FaGift className="contact text-black text-2xl" />
            </button>
          ) : giftButton ? (
            <button
              className="flex items-center justify-center p-3"
              onClick={() => toggleModal("salamkaut")}
            >
              <FaGift className="contact text-black text-2xl" />
            </button>
          ) : null}
          {/* {salamKaut ? (
            <button
              rel="noopener noreferrer"
              className="flex items-center justify-center p-3"
              onClick={() => toggleModal("salamkaut")}
            >
              <FaGift className="contact text-black text-2xl" />
            </button>
          ) : (
            <div>
              {giftButton ? (
                <a
                  rel="noopener noreferrer"
                  className="flex items-center justify-center p-3"
                  href="https://mycollection.shop/ideahadiahkahwin/"
                  target="_blank"
                >
                  <FaGift className="contact text-black text-2xl" />
                </a>
              ) : (
                <></>
              )}
            </div>
          )} */}

          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-3"
            onClick={() => toggleModal("date")}
          >
            <FaClock className="contact text-black text-2xl" />
          </button>
        </div>
      </div>
      {/* <div className="fixed bottom-5 right-5 z-30 flex items-center justify-center space-x-4">
        <div
          className={`flex space-x-2 mt-4 transition-all duration-500 ease-in-out transform ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
          }`}
        >
          <button
            className="flex items-center justify-center p-4 bg-secondary-500 text-neutral-50 shadow-lg rounded-full transition-transform duration-300 ease-in-out"
            onClick={() => toggleModal("contacts")}
          >
            <FaPhone className="contact text-2xl" />
          </button>

          <button
            className="flex items-center justify-center p-4 bg-secondary-500 text-neutral-50 shadow-lg rounded-full transition-transform duration-300 ease-in-out"
            onClick={() => toggleModal("location")}
          >
            <FaMapMarkerAlt className="contact text-2xl" />
          </button>

          {rsvp && (
            <button
              className="flex items-center justify-center p-4 bg-secondary-500 text-neutral-50 shadow-lg rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => toggleModal("rsvp")}
            >
              <FaCalendarCheck className="contact text-2xl" />
            </button>
          )}

          {salamKaut ? (
            <button
              className="flex items-center justify-center p-4 bg-secondary-500 text-neutral-50 shadow-lg rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => toggleModal("salamkaut")}
            >
              <FaGift className="contact text-2xl" />
            </button>
          ) : giftButton ? (
            <button
              className="flex items-center justify-center p-4 bg-secondary-500 text-neutral-50 shadow-lg rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => toggleModal("salamkaut")}
            >
              <FaGift className="contact text-2xl" />
            </button>
          ) : null}
        </div>
        <button
          className="flex items-center justify-center mt-4 p-4 bg-white shadow-lg rounded-full transition-transform duration-300 ease-in-out"
          onClick={toggleButtons}
        >
          <FaPlus className={`text-black text-2xl transform transition-transform ${isVisible ? 'rotate-45' : 'rotate-0'}`} />
        </button>
      </div> */}

      {openModalType === "contacts" && (
        <motion.div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
          initial={{ opacity: 0 }} // Initial state for overlay
          animate={{ opacity: 1 }} // Animate to full opacity
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
            variants={modalVariants}  // Use defined variants
            initial="hidden"          // Start with hidden variant
            animate="visible"         // Animate to visible when opened
            exit="exit"               // Exit with exit variant
            transition={{ duration: 0.5 }}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveContacts contacts={contacts} />
          </motion.div>
        </motion.div>
      )}

      {openModalType === "location" && (
        <motion.div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
          initial={{ opacity: 0 }} // Initial state for overlay
          animate={{ opacity: 1 }} // Animate to full opacity
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
            variants={modalVariants}  // Use defined variants
            initial="hidden"          // Start with hidden variant
            animate="visible"         // Animate to visible when opened
            exit="exit"               // Exit with exit variant
            transition={{ duration: 0.5 }}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveLocation directions={directions} />
          </motion.div>
        </motion.div>
      )}

      {openModalType === "rsvp" && (
        <motion.div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
          initial={{ opacity: 0 }} // Initial state for overlay
          animate={{ opacity: 1 }} // Animate to full opacity
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={`bg-white py-8 h-5/6 overflow-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
            variants={modalVariants}  // Use defined variants
            initial="hidden"          // Start with hidden variant
            animate="visible"         // Animate to visible when opened
            exit="exit"               // Exit with exit variant
            transition={{ duration: 0.5 }}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveRSVP />
          </motion.div>
        </motion.div>
      )}

      {openModalType === "salamkaut" && (
        <motion.div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
          initial={{ opacity: 0 }} // Initial state for overlay
          animate={{ opacity: 1 }} // Animate to full opacity
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={`bg-white py-8 h-5/6 overflow-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
            variants={modalVariants}  // Use defined variants
            initial="hidden"          // Start with hidden variant
            animate="visible"         // Animate to visible when opened
            exit="exit"               // Exit with exit variant
            transition={{ duration: 0.5 }}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            {salamKaut && (<LiveSalamKaut fontP={fontP} salamKaut={salamKaut} />)}
            {giftButton ? (
              <GiftIdeas />
            ) : (
              <></>
            )}
          </motion.div>
        </motion.div>
      )}

      {openModalType === "date" && (
        <motion.div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
          initial={{ opacity: 0 }} // Initial state for overlay
          animate={{ opacity: 1 }} // Animate to full opacity
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
            variants={modalVariants}  // Use defined variants
            initial="hidden"          // Start with hidden variant
            animate="visible"         // Animate to visible when opened
            exit="exit"               // Exit with exit variant
            transition={{ duration: 0.5 }}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveDate
              weddingDate={weddingDate}
              startTime={startTime}
              endTime={endTime}
              fontP={fontP}
            />
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}

export default LiveBottomBar;
