import axios from "axios";

const sendEmail = async (email, otp) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/send-otp`, {
      email,
      otp,
    });
    console.log(response.data.message); // Log success message
  } catch (error) {
    console.error("Failed to send email", error);
  }
};

export default sendEmail;
