import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import LoadingSpinner from '../LoadingSpinner';
import Themes from '../Themes';

function EditHero({ setUpdatedWeddingDate, setUpdatedWalimatulurus, updatedH2, updatedFontP }) {
  const theme = localStorage.getItem('theme');
  const localStorageWalimatulurus = localStorage.getItem('walimatulurus');
  const localStorageGroomName = localStorage.getItem('groom_name');
  const localStorageBrideName = localStorage.getItem('bride_name');
  const localStorageWeddingDate = localStorage.getItem('wedding_date');
  const selectedH2 = localStorage.getItem('H2');
  const selectedfontP = localStorage.getItem('fontP');
  const selectedH2size = localStorage.getItem('H2size');
  
  const Components = Themes[theme];

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true)
  }, []);

  const [walimatulurus, setWalimatulurus] = useState(localStorageWalimatulurus);
  const [groomName, setGroomName] = useState(localStorageGroomName);
  const [brideName, setBrideName] = useState(localStorageBrideName);
  const [weddingDate, setWeddingDate] = useState(new Date(localStorageWeddingDate));
  const [weddingDay, setWeddingDay] = useState(getDayFromDate(weddingDate));

  const formattedDate = weddingDate.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    localStorage.setItem('day', days[dayIndex]);
    return days[dayIndex];
  }

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const center = {
    backgroundImage: `url(${Components.fc})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const handleWalimatulurusChange = (event) => {
    const newWalimatulurus = event.target.value;
    setWalimatulurus(newWalimatulurus);
    setUpdatedWalimatulurus(newWalimatulurus);
    localStorage.setItem('walimatulurus', newWalimatulurus);
    localStorage.setItem('walimatulurus2', newWalimatulurus);
  };

  const handleGroomNameChange = (event) => {
    const newGroomName = event.target.value;
    setGroomName(newGroomName);
    localStorage.setItem('groom_name', newGroomName);
    const inputElement = event.target;
    inputElement.style.minWidth = `${newGroomName.length * 5}px`;
  };

  const handleBrideNameChange = (event) => {
    const newBrideName = event.target.value;
    setBrideName(newBrideName);
    localStorage.setItem('bride_name', newBrideName);
  };
  
  const [editingDate, setEditingDate] = useState(false);

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setWeddingDate(newDate);
    getDayFromDate(newDate);

    localStorage.setItem('wedding_date', newDate);
    setUpdatedWeddingDate(newDate);
    // Calculate the day from the selected date
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = newDate.getDay();
    const day = days[dayIndex];
    setWeddingDay(day);

  };

  function handleOk() {
    setIsLoading(true);
    window.location.reload();
  }

  const handleBlur = () => {
    // window.location.reload();
  };

  const H2 = updatedH2 || selectedH2;
  const fontP = updatedFontP || selectedfontP;
  const H2size = selectedH2size;

  return (
    <div className="h-screen relative flex flex-col justify-center items-center" style={heroStyle}>
      {/* <div className="text-center p-20 md:w-1/2 sm:w-2/3 w-full z-10 text-black" style={center}> */}
      <div className={`${Components.fcStyle} z-10`} style={center}>
        {/* <p className={`text-l ${fontP} uppercase`}>Walimatulurus</p> */}

        <input
          type="text"
          value={walimatulurus}
          onChange={handleWalimatulurusChange}
          onBlur={handleBlur}
          className={`text-l ${fontP} uppercase bg-transparent w-full max-w-xs text-center`}
        />

        <div>
          <input
            type="text"
            onClick={() => setIsOpen(false)}
            value={groomName}
            onChange={handleGroomNameChange}
            className={`input-groomname ${H2size} ${H2} capitalize bg-transparent w-full max-w-xs text-center`}
          />

          <Tooltip anchorSelect=".input-groomname" place="bottom" isOpen={isOpen} variant="error">
            Klik untuk ubah nama pengantin
          </Tooltip>

          <p className={`text-3xl ${H2} capitalize`}>&</p>

          <input
            type="text"
            value={brideName}
            onChange={handleBrideNameChange}
            className={`${H2size} ${H2} capitalize bg-transparent w-full max-w-xs text-center`}
          />

        </div>

        <div>
          <p className={`text-l ${fontP} mb-2`}>{weddingDay}, {formattedDate}</p>
          {editingDate ? (
            <div className="mb-2">
              <input
                type="date"
                value={weddingDate.toISOString().split('T')[0]}
                onChange={handleDateChange}
                className="border border-gray-400 p-2 rounded-lg"
              />
              <button onClick={() => setEditingDate(false)} className="text-sm text-neutral-50 md:px-4 py-3 md:w-14 w-full bg-secondary-700 hover:bg-red-600 rounded-lg">OK</button>
            </div>
          ) : (
            <button onClick={() => setEditingDate(true)} className="text-sm text-blue-500">Ubah Tarikh</button>
          )}
        </div>
      </div>
      <img
        src={Components.ft}
        
        className={Components.ftStyle}
      />
      <img
        src={Components.fb}
        
        className={Components.fbStyle}
      />
      {isLoading && <LoadingSpinner />}
    </div>
  );
}

export default EditHero;
