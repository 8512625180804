import React, { useState, useEffect } from 'react';
import { FaPhone, FaWhatsapp } from 'react-icons/fa';

function EditContacts() {

  const [contacts, setContacts] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    contactMethod: 'both', // Default to both, other options: 'whatsapp', 'phonecall'
  });

  useEffect(() => {
    const storedContacts = localStorage.getItem('contacts');
    if (storedContacts) {
      setContacts(JSON.parse(storedContacts));
    }
  }, []); 

  const handleFormSubmit = () => {
    const updatedContacts = [...contacts, formData];
    setContacts(updatedContacts);
    setFormData({
      name: '',
      phoneNumber: '',
      contactMethod: 'both',
    });

    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
  };

  const handleRemoveContact = (index) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(index, 1);
    setContacts(updatedContacts);
    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: inputValue });
  };

  return (
    <div className="relative">
      <div className='mb-10'>
      {contacts.map((contact, index) => (
          <div key={index} className="border p-2 my-2 rounded flex items-center justify-between">
            <p>{contact.name}</p>
            <div className="flex space-x-2">
              {contact.contactMethod === 'whatsapp' && (
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-500 text-neutral-50">
                  <FaWhatsapp />
                </div>
              )}
              {contact.contactMethod === 'phonecall' && (
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-neutral-50">
                  <FaPhone />
                </div>
              )}
              {contact.contactMethod === 'both' && (
                <>
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-500 text-neutral-50">
                    <FaWhatsapp />
                  </div>
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-neutral-50">
                    <FaPhone />
                  </div>
                </>
              )}
              <button
                onClick={() => handleRemoveContact(index)}
                className="text-red-700 px-2 py-1"
              >
                X
              </button>
            </div>
          </div>
        ))}
      </div>
      <form className="flex flex-col" onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
        {/* Your form fields go here */}
        <label className="mb-2 text-sm">Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="mb-4 border p-2 rounded"
          required
        />

        <label className="mb-2 text-sm">Phone Number:</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          className="mb-4 border p-2 rounded"
          placeholder="e.g., 0123334444"
          required
        />

        <div className="mb-4">
          <label className="inline-flex items-center text-sm">
            <input
              type="radio"
              name="contactMethod"
              value="whatsapp"
              checked={formData.contactMethod === 'whatsapp'}
              onChange={handleInputChange}
              className="ml-2"
            />
            <span className="ml-1">WhatsApp</span>
          </label>
          <label className="inline-flex items-center text-sm ml-4">
            <input
              type="radio"
              name="contactMethod"
              value="phonecall"
              checked={formData.contactMethod === 'phonecall'}
              onChange={handleInputChange}
              className="ml-2"
            />
            <span className="ml-1">Phone Call</span>
          </label>
          <label className="inline-flex items-center text-sm ml-4">
            <input
              type="radio"
              name="contactMethod"
              value="both"
              checked={formData.contactMethod === 'both'}
              onChange={handleInputChange}
              className="ml-2"
            />
            <span className="ml-1">Both</span>
          </label>
        </div>

        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 text-neutral-50 px-4 py-2 rounded hover:bg-blue-700 w-full"
          >
            Add Contact
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditContacts;
