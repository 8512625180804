import React, { useState, useEffect } from 'react';
import { FaPhone, FaWhatsapp } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import { Helmet } from 'react-helmet';
import BackButtonWhite from '../components/BackButtonWhite';

function AddContacts() {

  const [contacts, setContacts] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    contactMethod: 'both', // Default to both, other options: 'whatsapp', 'phonecall'
  });

  useEffect(() => {
    // Load contacts from localStorage when the component mounts
    const storedContacts = localStorage.getItem('contacts');
    if (storedContacts) {
      setContacts(JSON.parse(storedContacts));
    }
  }, []); // Empty dependency array ensures this runs only once, equivalent to componentDidMount
  const checkContacts = () => {
    // Check if 'contacts' exist in localStorage
    const contactsInLocalStorage = JSON.parse(localStorage.getItem('contacts'));
    setIsButtonDisabled(!contactsInLocalStorage || contactsInLocalStorage.length === 0);
  }

  useEffect(() => {
    checkContacts();
  }, []);

  const handleFormSubmit = () => {
    // Handle form submission logic here
    setIsButtonDisabled(false);
    const updatedContacts = [...contacts, formData];
    setContacts(updatedContacts);
    setFormData({
      name: '',
      phoneNumber: '',
      contactMethod: 'both',
    });

    // Save the updated contacts array to localStorage
    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
  };

  const handleRemoveContact = (index) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(index, 1);
    setContacts(updatedContacts);
    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
    checkContacts();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: inputValue });
  };

  return (
    <div>
      <Helmet>
        <title>Tambah Contacts | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <BackButtonWhite />
      <NavBar />
      <div className="py-24 px-8">
        <div className="md:max-w-xl md:mx-auto bg-white p-4 md:p-8 md:rounded md:shadow-lg">
          <h1 className="text-3xl mb-6 text-center font-semibold">Tambah Contacts</h1>

          <div className="mb-12">
            <p className="text-lg text-center text-gray-700">
              Masukkan maklumat contact untuk tetamu anda hubungi untuk bertanya berkenaan majlis.
            </p>
          </div>

          <div className='mb-10'>
          {contacts.map((contact, index) => (
              <div key={index} className="border p-2 my-2 rounded flex items-center justify-between">
                <p>{contact.name}</p>
                <div className="flex space-x-2">
                  {contact.contactMethod === 'whatsapp' && (
                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-500 text-neutral-50">
                      <FaWhatsapp />
                    </div>
                  )}
                  {contact.contactMethod === 'phonecall' && (
                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-neutral-50">
                      <FaPhone />
                    </div>
                  )}
                  {contact.contactMethod === 'both' && (
                    <>
                      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-500 text-neutral-50">
                        <FaWhatsapp />
                      </div>
                      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-neutral-50">
                        <FaPhone />
                      </div>
                    </>
                  )}
                  <button
                    onClick={() => handleRemoveContact(index)}
                    className="text-red-700 px-2 py-1"
                  >
                    X
                  </button>
                </div>
              </div>
            ))}
          </div>
          <form className="flex flex-col" onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
            {/* Your form fields go here */}
            <label className="mb-2 text-sm">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="mb-4 border p-2 rounded"
              required
            />

            <label className="mb-2 text-sm">Phone Number:</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="mb-4 border p-2 rounded"
              placeholder="e.g., 0123334444"
              required
            />

            <div className="mb-4">
              <label className="inline-flex items-center text-sm">
                <input
                  type="radio"
                  name="contactMethod"
                  value="whatsapp"
                  checked={formData.contactMethod === 'whatsapp'}
                  onChange={handleInputChange}
                  className="ml-2"
                />
                <span className="ml-1">WhatsApp</span>
              </label>
              <label className="inline-flex items-center text-sm ml-4">
                <input
                  type="radio"
                  name="contactMethod"
                  value="phonecall"
                  checked={formData.contactMethod === 'phonecall'}
                  onChange={handleInputChange}
                  className="ml-2"
                />
                <span className="ml-1">Phone Call</span>
              </label>
              <label className="inline-flex items-center text-sm ml-4">
                <input
                  type="radio"
                  name="contactMethod"
                  value="both"
                  checked={formData.contactMethod === 'both'}
                  onChange={handleInputChange}
                  className="ml-2"
                />
                <span className="ml-1">Both</span>
              </label>
            </div>

            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-blue-500 text-neutral-50 px-4 py-2 rounded hover:bg-blue-700 w-full"
              >
                Add Contact
              </button>
            </div>
          </form>

          <div className="text-center flex justify-between mt-4">
          <button
            className={`bg-secondary-500 text-neutral-50 px-4 py-2 rounded hover:bg-red-600 w-full ${isButtonDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
            onClick={() => {
              if (!isButtonDisabled) {
                window.location.href = '/daftar';
              }
            }}
            disabled={isButtonDisabled}
          >
            Submit
          </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddContacts;
