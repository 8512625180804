import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import logo from '../img/logo.png';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Publish = () => {
  const { name } = useParams();
  const { email } = useParams();
  const { mobile } = useParams();
  const { total } = useParams();
  const { promo } = useParams();
  const localStorageTheme = localStorage.getItem('theme');
  const localStorageWalimatulurus = localStorage.getItem('walimatulurus');
  const localStorageWalimatulurus2 = 
  (localStorage.getItem('walimatulurus2') === "") ? "" :
  (localStorage.getItem('walimatulurus2') === undefined) ? "Walimatulurus" :
  (localStorage.getItem('walimatulurus2') !== null && localStorage.getItem('walimatulurus2') !== undefined && localStorage.getItem('walimatulurus2') !== "") ? localStorage.getItem('walimatulurus2') : null;

  const localStorageToggleWalima = localStorage.getItem('toggle_walima');
  const localStorageDoa = localStorage.getItem('doa');
  const localStorageGroomName = localStorage.getItem('groom_name');
  const localStorageBrideName = localStorage.getItem('bride_name');

  const localStorageParentName = localStorage.getItem('parent_name');
  const localStorageMessage = localStorage.getItem('message');
  const localStorageGroomFullName = localStorage.getItem('groom_fullname');
  const localStorageBrideFullName = localStorage.getItem('bride_fullname');

  const localStorageWeddingDate = localStorage.getItem('wedding_date');
  const localStorageLocation = localStorage.getItem('location');
  const localStorageStartTime = localStorage.getItem('start_time');
  const localStorageEndTime = localStorage.getItem('end_time');

  const localStorageTentative = localStorage.getItem('tentative');

  const localStorageContacts = localStorage.getItem('contacts');
  const localStorageDirections = localStorage.getItem('directions');

  const localStorageH2 = localStorage.getItem('H2');
  const localStorageH2size = localStorage.getItem('H2size');
  const localStorageFontP = localStorage.getItem('fontP');
    
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const defaultTentative = [
    { title: "Kehadiran tetamu", displaytime: "11:30 AM", time:"11:30"},
    { title: "Ketibaan Pengantin", displaytime: "12:30 PM", time:"12:30"},
    { title: "Makan Beradab", displaytime: "1:30 PM", time:"13:30"},
    { title: "Majlis Berakhir", displaytime: "4:00 PM", time:"16:00"},
  ];

  const demoContacts = [
    {
      "name": "Adam",
      "phoneNumber": "0123456789",
      "contactMethod": "both"
    },
  ];

  const demoDirections = [
    {
      "locationName": "Corus Hotel",
      "wazeUrl": "https://ul.waze.com/ul?preview_venue_id=66650144.666566972.474270&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",
      "googleMapsUrl": "https://maps.app.goo.gl/FxK74Gyh4wxyP1Nx9"
    }
  ];

  const theme = (localStorageTheme || "theme3");
  const walimatulurus = (localStorageWalimatulurus || "Walimatulurus");
  const walimatulurus2 = (localStorageWalimatulurus2 || "Walimatulurus");
  const toggle_walima = (localStorageToggleWalima || true);
  const doa = (localStorageDoa || "Ya Allah Ya Rahman Ya Rahim.\nBerkatilah majlis perkahwinan ini.\nLimpahkanlah baraqah dan rahmatMu kepada kedua mempelai ini. Kurniakanlah mereka kelak zuriat yang soleh dan solehah. Kekalkanlah jodoh mereka hingga ke jannah.");
  const groom_name = (localStorageGroomName || "Adam");
  const bride_name = (localStorageBrideName || "Hawa");
  const wedding_date = (localStorageWeddingDate ? new Date(localStorageWeddingDate) : tomorrow);

  const parent_name = (localStorageParentName || "Abdul Razak bin Abdullah\n&\nAminah binti Ali");
  const message = (localStorageMessage || "Dengan penuh kesyukuran, kami menjemput Dato' | Datin | Tuan | Puan | Encik | Cik seisi keluarga hadir ke majlis perkahwinan anakanda kami");
  const groom_fullname = (localStorageGroomFullName || "Muhammad Adam bin Abdul Razak");
  const bride_fullname = (localStorageBrideFullName || "Siti Hawa binti Faizal");

  const location = (localStorageLocation || "Corus Hotel, Kuala Lumpur");
  const start_time = (localStorageStartTime || "11:30");
  const end_time = (localStorageEndTime || "17:00");

  const tentative = (localStorageTentative || defaultTentative);

  const contacts = (localStorageContacts || JSON.stringify(demoContacts));
  const directions = (localStorageDirections || JSON.stringify(demoDirections));

  const H2 = localStorageH2;
  const H2size = localStorageH2size;
  const fontP = localStorageFontP;
  const status = "unverified";
  const gift_button = "true";
  
  useEffect(() => {
    const apiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/invitation-cards`; 

    const dataToSave = {
      name,
      email,
      mobile,
      promo,
      theme,
      walimatulurus,
      walimatulurus2,
      toggle_walima,
      doa,
      groom_name,
      bride_name,
      wedding_date,
      parent_name,
      message,
      groom_fullname,
      bride_fullname,
      location,
      start_time,
      end_time,
      tentative,
      contacts,
      directions,
      H2,
      fontP,
      H2size,
      status,
      gift_button,
    };

    axios.post(apiEndpoint, dataToSave)
      .then(response => {
        const card_id = response.data;

        const amount = Math.round(total * 100);

        if (parseInt(total) === 0) {
          window.location.href = `/dashboard/${card_id}`;
          return;
        }
        else {
          const submitURL = `https://pay.invitekahwin.com/billplzpost.php?clientId=${response.data}&name=${name}&email=${email}&mobile=${mobile}&amount=${amount}&promo_code=${promo}&reference_1_label=&reference_2_label=`;
          window.location.href = submitURL;
          return; 
        }
      })
      .catch(error => {
        console.error('Error saving data:', error);
      });
  }, []);

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
      <Helmet>
        <title>Publish | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <FaSpinner className="text-4xl text-secondary-500 animate-spin" />
      <img src={logo} alt="Logo" className="h-8 mb-4" />
    </div>
  );
};

export default Publish;
