import React, { useState } from 'react';
import logo from '../img/logo.png';
import { FaBars } from 'react-icons/fa';

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='fixed w-full z-50'>
      {/* <a href='https://wa.me/601127287527?text=Beli%20Bisnes' className='flex justify-center mx-auto text-sm w-full py-2 bg-yellow-400 hover:bg-yellow-500'>
        Bisnes ini untuk dijual. Klik sini jika berminat.
      </a> */}
      <header className="bg-neutral-50 shadow-md py-4">
        <div className="flex items-center justify-between px-6">
          {/* <p>Bisnes Ini Untuk Dijual</p>
          <a href='https://wa.me/601127287527?text=Beli%20Bisnes' className='text-center px-3 py-1 bg-green-500 rounded-full text-neutral-50 font-bold'>WhatsApp Saya</a>
           */}
          <div></div>
          <a href="/"><img src={logo} alt="Logo" className='h-10' /></a>
          <div className="">
            <button onClick={toggleMenu} className="text-neutral-50 focus:outline-none">
              <FaBars />
            </button>
          </div>
        </div>
        <div className={`bg-secondary-500 shadow-md absolute w-full top-16 transition-all duration-300 ${isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
          {/* Menu items */}
          <a href="dashboard" className="text-center block px-4 py-4 hover:bg-primary-700 text-neutral-50">Log Masuk</a>
        </div>
      </header>
    </div>
  );
}

export default NavBar;
