import React, { useState } from 'react';
import NavBar from '../../components/NavBar';
import axios from 'axios';
import sendEmail from '../SendEmail';
// import sendEmail from '../../pages/SendEmail';

function LogMasuk() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [countdown, setCountdown] = useState();

  const [otp, setOtp] = useState('');
  const [verify, setVerify] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const lowercaseEmail = value.toLowerCase();
      setEmail(lowercaseEmail);
    }
  };

  const handleLogin = async () => {
    const token = generateToken();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/sessions`, {
        email: email,
        token: token,
      })
        .then(response => {
          localStorage.setItem('userEmail', email);
          localStorage.setItem('token', token);
          console.log('Successfully logged in!');
          window.location.reload();
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.error('Error logging in:', error.message);
    }
  };

  const generateToken = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const enableResend = () => {
    setButtonEnabled(false);
    setCountdown(30); // Reset countdown timer

    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000); // Update countdown every second

    // // Enable the button after countdown finishes
    setTimeout(() => {
      clearInterval(timer);
      setButtonEnabled(true);
    }, 30000);
  };

  const verifyEmail = async (e) => {
    e.preventDefault();
    setMessage('');
    const otp = generateOTP();
    setPasscode(otp);
    // console.log('OTP: ',otp);

    try {
      // Check if the email exists in the Laravel backend
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/check-email`, { email });

      if (response.status === 200) {
        setCheckEmail(true);
        sendEmail(email, otp);
        // console.log('Message: Email sent');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // console.log('Email not exist');
      } else {
        // console.log('An error occurred. Please try again.');
      }
    }
    enableResend();
    setVerify(true);
  }

  const resend = () => {
    enableResend();
    if(checkEmail === true){
      sendEmail(email, passcode);
    }
  }

  function generateOTP() {
    const digits = '0123456789';
    let OTP = '';

    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }

    return OTP;
  }

  const verifyOTP = (e) => {
    e.preventDefault();
    if(otp === passcode) {
      handleLogin();
    } else {
      setMessage("Maaf. Kod OTP tidak tepat.");
    }
  }

  return (
    <div>
      <NavBar />
      <div className="py-24 px-8">
        <div className="md:max-w-xl md:mx-auto bg-white p-4 md:p-8 md:rounded md:shadow-lg">
          {verify ? (
            <div className="max-w-lg mx-auto p-4">
              <h2 className="text-2xl font-semibold mb-4">Sahkan email anda</h2>
              <p className="text-lg mb-4">Jika pernah daftar, anda akan menerima 6 digit kod OTP yang telah dihantar ke email {email}:</p>
              <button className="text-md text-secondary-500 underline mb-4" onClick={() => setVerify(false)}>Nak ubah email? Klik sini</button>
              <form onSubmit={verifyOTP}>
                <div className="mb-4">
                  <label className="block text-gray-700">OTP:</label>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder='XXXXXX'
                    className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                    required
                  />
                </div>
                <p className='mt-4 text-secondary-500 text-center'>{message}</p>
                <div className='flex space-x-2'>
                  <button
                    type="submit"
                    className="bg-secondary-500 text-neutral-50 py-2 px-4 rounded hover:bg-secondary-700 focus:outline-none focus:ring focus:border-red-300"
                  >
                    Sahkan
                  </button>
                </div>
              </form>
              <button
                className={`${buttonEnabled ? 'bg-green-400 hover:bg-green-500 focus:border-green-300' : 'bg-gray-400 hover:bg-gray-500 focus:border-gray-300'} mt-4 text-neutral-50 py-2 px-4 rounded focus:outline-none focus:ring`}
                onClick={resend} disabled={!buttonEnabled}
              >
                {buttonEnabled ? 'Resend OTP' : `Resend OTP (${countdown})`}
              </button>
            </div>
          ):(
            <div>
              <h1 className="text-3xl mb-6 text-center font-semibold">Log Masuk</h1>
    
              <div className="mb-12">
                <p className="text-lg text-center text-gray-700">
                  Masukkan email untuk log masuk ke akaun anda.
                </p>
              </div>
              <form onSubmit={verifyEmail}>
                <div className="mb-6">
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                      Email
                    </label>
                    <div className='flex'>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="mt-1 p-2 w-full border rounded-md"
                        placeholder="Masukkan email"
                        required
                        value={email}  // Update to use the state variable
                        onChange={handleChange}  // Update to use the handleChange function
                      />
                    </div>
                  </div>
                  <p className='mt-4 text-secondary-500 text-center'>{message}</p>
                  <button
                    className={`bg-secondary-500 text-neutral-50 px-4 py-2 rounded hover:bg-red-600 w-full`}
                    type="submit"
                  >
                    Log Masuk
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogMasuk;