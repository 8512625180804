import React from 'react';
import { useParams } from 'react-router-dom';
import logo from '../img/logo-black.png';
import ProductList from '../components/ProductList';
import { Helmet } from 'react-helmet';

const Reward = () => {
  return (
    <div>
      <Helmet>
        <title>Reward | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <header className="w-full bg-secondary-500 shadow-md py-4 z-10">
        <div className="flex items-center justify-center px-6">
          <a href="/"><img src={logo} alt="Logo" className='h-10' /></a>
        </div>
      </header>
      <div className="md:p-20 flex items-center justify-center">
        <div className="bg-white md:px-8 py-12 rounded-md md:shadow-lg max-w-xl w-full text-center">
          <h1 className="text-2xl font-bold mb-4">Reward</h1>
          <p className="text-gray-700 mb-4">
             Istimewa untuk anda pengguna Kad Kahwin Digital di invitekahwin.com
          </p>
          <ProductList />
        </div>
      </div>
    </div>
  );
};

export default Reward;
