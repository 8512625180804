import axios from 'axios';

const sendEmail = async (to, templateId, params) => {
  try {
    const response = await axios.post(
      'https://api.brevo.com/v3/smtp/email',
      {
        to: [{ email: to }],
        templateId: templateId,
        params: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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',
        },
      }
    );

    console.log('Email sent successfully:', response.data);

  } catch (error) {
    console.error('Error sending email:', error.response.data);
  }
};

export default sendEmail;
