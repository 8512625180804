import React from "react";

const Footer = () => {
  return (
    <footer className="bg-secondary-500 text-neutral-50 py-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        {/* Left Section */}
        <div className="text-sm md:text-base">
          <p>&copy; {new Date().getFullYear()} InviteKahwin.com | All rights reserved.</p>
        </div>

        {/* Right Section */}
        {/* <div className="flex space-x-4 mt-4 md:mt-0">
          <a
            href="#"
            className="hover:underline text-sm md:text-base"
          >
            Privacy Policy
          </a>
          <a
            href="#"
            className="hover:underline text-sm md:text-base"
          >
            Terms of Service
          </a>
          <a
            href="#"
            className="hover:underline text-sm md:text-base"
          >
            Contact Us
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
