import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

const FeedbackList = ({ feedback }) => {
  const [selectedRating, setSelectedRating] = useState("all");
  const [filteredFeedback, setFilteredFeedback] = useState(feedback);

  const handleFilterChange = (rating) => {
    if (rating === "all") {
      setFilteredFeedback(feedback);
    } else {
      const filtered = feedback.filter(
        (item) => item.rating === rating.toString()
      );
      setFilteredFeedback(filtered);
    }
    setSelectedRating(rating);
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-center mb-4">
        <button
          className={`px-4 py-2 rounded-lg focus:outline-none ${
            selectedRating === "all"
              ? "bg-secondary-500 text-neutral-50"
              : "bg-gray-200 text-gray-800"
          }`}
          onClick={() => handleFilterChange("all")}
        >
          All
        </button>
        {[1, 2, 3, 4, 5].map((rating) => (
          <button
            key={rating}
            className={`px-4 py-2 rounded-lg focus:outline-none ${
              selectedRating === rating
                ? "bg-secondary-500 text-neutral-50"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => handleFilterChange(rating)}
          >
            {rating}{" "}
            <FaStar
              className={`w-4 h-4 fill-current ${
                selectedRating === rating ? "text-neutral-50" : "text-yellow-500"
              }`}
            />
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 gap-6">
        {filteredFeedback.map((item) => (
          <div
            key={item.id}
            className="border rounded-lg overflow-hidden shadow-md"
          >
            <div className="p-6">
              <p className="text-gray-600 mb-4">{item.comment}</p>
              <div className="flex justify-between">
                <h2 className="text-gray-600">{item.name}</h2>
                <div className="flex items-center">
                  <FaStar className="w-6 h-6 fill-current text-yellow-500 mr-1" />
                  <span className="text-gray-700">{item.rating}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedbackList;
