const Fonts = { 
  f1: {
      H2: 'font-great-vibes',
      fontP: 'font-montserrat',
  },
  f2: {
      H2: 'font-dancing-script',
      fontP: 'font-lato',
  },
  f7: {
      H2: 'font-playfair-display',
      fontP: 'font-montserrat',
  },
  f8: {
      H2: 'font-alex-brush',
      fontP: 'font-montserrat',
  },
  f9: {
      H2: 'font-ephesis',
      fontP: 'font-normal',
  },
  f11: {
      H2: 'font-imperial-script',
      fontP: 'font-montserrat',
  },
  f12: {
      H2: 'font-lovers-quarrel',
      fontP: 'font-montserrat',
  },
  f13: {
      H2: 'font-monte-carlo',
      fontP: 'font-normal',
  },
  f14: {
      H2: 'font-parisienne',
      fontP: 'font-montserrat',
  },
  f15: {
      H2: 'font-rosarivo',
      fontP: 'font-montserrat',
  },
  f16: {
      H2: 'font-rouge-script',
      fontP: 'font-montserrat',
  },
  f17: {
      H2: 'font-style-script',
      fontP: 'font-montserrat',
  },
  f18: {
      H2: 'font-tangerine',
      fontP: 'font-lato',
  },
};

export default Fonts;
