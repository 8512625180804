import React from 'react';
import logo from '../img/logo-black.png';
import { Helmet } from 'react-helmet';

const Pengesahan = () => {
  const email = localStorage.getItem('email');

  return (
    <div>
      <Helmet>
        <title>Pengesahan | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <header className="w-full bg-secondary-500 shadow-md py-4 z-10">
        <div className="flex items-center justify-center px-6">
          <a href="/"><img src={logo} alt="Logo" className='h-10' /></a>
        </div>
      </header>
      <div className="md:p-24 flex items-center justify-center">
        <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full text-center">
          <h1 className="text-2xl font-bold mb-4">Berjaya!</h1>
          {/* <h1 className="text-2xl font-bold mb-4">Email anda telah disahkan.</h1> */}
          <div className='mb-4 space-y-4'>
            <p className="text-gray-700">
              Pautan untuk akses ke Kad Kahwin Digital telah dihantar ke email {email}.
            </p>
            <p className="text-gray-700">Cek inbox/spam email anda.</p>
          </div>
          <div className="text-center mt-12 mb-6">
            <a
              className='text-green-500 hover:text-green-600 font-semibold'
              href="https://api.whatsapp.com/send?phone=601127287527&text=Masalah%20Tak%20Terima%20Pautan%20Akses%20invitekahwin.com"
              target="_blank"
              rel="noreferrer"
            >
              Tak terima pautan untuk akses di email? Klik sini untuk WhatsApp kami.
            </a>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Pengesahan;
