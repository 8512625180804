import React, { useState, useEffect } from 'react';
import { FaWaze, FaGoogle } from 'react-icons/fa';

function DemoLocation() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [locations, setLocations] = useState([]);

  const demoDirections = [
    {
      "locationName": "Corus Hotel",
      "wazeUrl": "https://ul.waze.com/ul?preview_venue_id=66650144.666566972.474270&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",
      "googleMapsUrl": "https://maps.app.goo.gl/FxK74Gyh4wxyP1Nx9"
    }
  ];

  useEffect(() => {
    const localStorageDirections = localStorage.getItem('directions');
    const storedLocations = (localStorageDirections || demoDirections);
    if (localStorageDirections) {
      setLocations(JSON.parse(storedLocations));
    }
    if (!localStorageDirections) {
      setLocations(storedLocations);
    }
  }, []); // Empty dependency array ensures this runs only once, equivalent to componentDidMount

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred on the overlay (background)
    if (e.target.classList.contains('overlay')) {
      handleCloseModal();
    }
  };

  return (
    <div className="relative">
      {locations.length > 0 && (
        <div className="border p-2 my-2 rounded flex items-center justify-between">
          <p>{locations[0].locationName}</p>
          <div className="flex space-x-2">
            <a
              className="flex items-center justify-center w-12 h-12 rounded-full bg-secondary-700 text-neutral-50 text-2xl"
              href={`${locations[0].googleMapsUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGoogle />
            </a>
            <a
              className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-300 text-black text-2xl"
              href={`${locations[0].wazeUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWaze />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default DemoLocation;
