import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import { Helmet } from 'react-helmet';
import BackButtonWhite from '../components/BackButtonWhite';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import logo2 from '../img/logo.png';

const Checkout2 = () => {
  const [promoCode, setPromoCode] = useState('');
  const [name, setName] = useState('');  // Add name state
  const [loading, setLoading] = useState(false);
  const email = localStorage.getItem('email');
  const [mobile, setMobile] = useState('');  // Add email state
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [errors, setErrors] = useState({
    name: '',
    mobile: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Use the appropriate state-setting function based on the input field's name
    if (name === 'fullName') {
      setName(value);
    } else if (name === 'mobile') {
      setMobile(value);
    } else if (name === 'promoCode') {
      setPromoCode(value);
    }
    // Add more conditions for other fields if needed
  };

  const handleSubmit = () => {
    setLoading(true);
    // Validate the form
    let formIsValid = true;

    if (!name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Sila masukkan nama anda.' }));
      formIsValid = false;
      setLoading(false);
    }

    if (!mobile) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Sila masukkan nombor telefon anda.',
      }));
      formIsValid = false;
      setLoading(false);
    }

    if (!email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Sila masukkan email anda.' }));
      formIsValid = false;
      setLoading(false);
    }

    // Check if all required fields are provided
    if (formIsValid) {
      const total = subtotal - discount;

      if (promoCode.trim() === '') {
        const publishURL = `/publish/${name}/${email}/${mobile}/${total}/none`;
        window.location.href = publishURL;
      } else {
        const publishURL = `/publish/${name}/${email}/${mobile}/${total}/${promoCode}`;
        window.location.href = publishURL;
      }
    } else {

    }
  };
  
  return (
    <div>
      <Helmet>
        <title>Maklumat Diri | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <BackButtonWhite />
      <NavBar />
      {loading && (
        <div className="fixed inset-0 h-screen w-full bg-white bg-opacity-80 flex flex-col items-center justify-center space-y-5">
          <FaSpinner className="text-4xl text-secondary-500 animate-spin" />
          <img src={logo2} alt="Logo" className="h-12 mb-4" />
        </div>
      )}
      <div className="py-24 px-8">
        <div className="md:max-w-xl md:mx-auto bg-white p-4 md:p-8 md:rounded md:shadow-lg">
          <h1 className="text-3xl mb-6 text-center font-semibold">Maklumat Diri</h1>

          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Masukkan nama dan nombor telefon anda dibawah:</h2>
            <div className="mb-4">
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-600">
                Nama
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                className="mt-1 p-2 w-full border rounded-md"
                placeholder="Masukkan nama anda disini"
                required
                value={name}  // Update to use the state variable
                onChange={handleChange}  // Update to use the handleChange function
              />
              {errors.name && <p className="text-secondary-700 text-sm mt-1">{errors.name}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="mobile" className="block text-sm font-medium text-gray-600">
                Phone Number
              </label>
              <div className='flex'>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  className="mt-1 p-2 w-full border rounded-md"
                  placeholder="Masukkan nombor telefon anda disini"
                  required
                  value={mobile}  // Update to use the state variable
                  onChange={handleChange}  // Update to use the handleChange function
                />
              </div>
              {errors.mobile && <p className="text-secondary-700 text-sm mt-1">{errors.mobile}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 w-full border rounded-md"
                value={email}
                onChange={handleChange}
                disabled
              />
              {errors.email && <p className="text-secondary-700 text-sm mt-1">{errors.email}</p>}
            </div>
          </div>

          <div className="text-center">
            <button
              className="bg-secondary-500 hover:bg-secondary-700 text-neutral-50 py-2 px-4 rounded-md font-semibold"
              type="button"
              onClick={handleSubmit}
            >
              Hantar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout2;
