
import theme1 from '../img/theme1.jpg';
import theme2 from '../img/theme2.jpg';
import theme3 from '../img/theme3.jpg';
import theme4 from '../img/theme4.jpg';
import theme5 from '../img/theme5.jpg';
import theme6 from '../img/theme6.jpg';
import theme7 from '../img/theme7.jpg';
import theme8 from '../img/theme8.jpg';
import theme9 from '../img/theme9.jpg';
import theme10 from '../img/theme10.jpg';
import theme11 from '../img/theme11.jpg';
import theme12 from '../img/theme12.jpg';
import theme13 from '../img/theme13.jpg';
import theme14 from '../img/theme14.jpg';
import theme15 from '../img/theme15.jpg';
import theme16 from '../img/theme16.jpg';
import theme17 from '../img/theme17.jpg';
import theme18 from '../img/theme18.jpg';
import theme19 from '../img/theme19.jpg';
import theme20 from '../img/theme20.jpg';
import theme21 from '../img/theme21.jpg';
import theme22 from '../img/theme22.jpg';
import theme23 from '../img/theme23.jpg';
import theme24 from '../img/theme24.jpg';
import theme25 from '../img/theme25.jpg';
import theme26 from '../img/theme26.jpg';
import theme27 from '../img/theme27.jpg';
import theme28 from '../img/theme28.jpg';
import theme29 from '../img/theme29.jpg';
import theme30 from '../img/theme30.jpg';
import theme31 from '../img/theme31.jpg';
import theme32 from '../img/theme32.jpg';
import theme33 from '../img/theme33.jpg';
import theme34 from '../img/theme34.jpg';
import theme35 from '../img/theme35.jpg';
import theme36 from '../img/theme36.jpg';
import theme37 from '../img/theme37.jpg';
import theme38 from '../img/theme38.jpg';
import theme39 from '../img/theme39.jpg';
import theme40 from '../img/theme40.jpg';
import theme41 from '../img/theme41.jpg';
import theme42 from '../img/theme42.jpg';
import theme43 from '../img/theme43.jpg';
import theme44 from '../img/theme44.jpg';
import theme45 from '../img/theme45.jpg';
import theme46 from '../img/theme46.jpg';
import theme47 from '../img/theme47.jpg';
import theme48 from '../img/theme48.jpg';
import theme49 from '../img/theme49.jpg';
import theme50 from '../img/theme50.jpg';
import theme51 from '../img/theme51.jpg';
import theme52 from '../img/theme52.jpg';
import theme53 from '../img/theme53.jpg';
import theme54 from '../img/theme54.jpg';
import theme55 from '../img/theme55.jpg';
import theme56 from '../img/theme56.jpg';
import theme57 from '../img/theme57.jpg';
import theme58 from '../img/theme58.jpg';
import theme59 from '../img/theme59.jpg';
import theme60 from '../img/theme60.jpg';
import theme61 from '../img/theme61.jpg';
import theme62 from '../img/theme62.jpg';
import theme63 from '../img/theme63.jpg';
import theme64 from '../img/theme64.jpg';
import theme65 from '../img/theme65.jpg';
import theme66 from '../img/theme66.jpg';
import theme67 from '../img/theme67.jpg';
import theme68 from '../img/theme68.jpg';
import theme69 from '../img/theme69.jpg';
import theme70 from '../img/theme70.jpg';
import theme71 from '../img/theme71.jpg';
import theme72 from '../img/theme72.jpg';
import theme73 from '../img/theme73.jpg';
import theme74 from '../img/theme74.jpg';
import theme75 from '../img/theme75.jpg';
import theme76 from '../img/theme76.jpg';
import theme77 from '../img/theme77.jpg';
import theme78 from '../img/theme78.jpg';
import theme79 from '../img/theme79.jpg';
import theme80 from '../img/theme80.jpg';
import theme81 from '../img/theme81.jpg';
import theme82 from '../img/theme82.jpg';
import theme83 from '../img/theme83.jpg';
import theme84 from '../img/theme84.jpg';
import theme85 from '../img/theme85.jpg';
import theme86 from '../img/theme86.jpg';
import theme87 from '../img/theme87.jpg';
import theme88 from '../img/theme88.jpg';
import theme89 from '../img/theme89.jpg';
import theme90 from '../img/theme90.jpg';
import theme91 from '../img/theme91.jpg';
import theme92 from '../img/theme92.jpg';
import theme93 from '../img/theme93.jpg';
import theme94 from '../img/theme94.jpg';
import theme95 from '../img/theme95.jpg';
import theme96 from '../img/theme96.jpg';
import theme97 from '../img/theme97.jpg';
import theme98 from '../img/theme98.jpg';
import theme99 from '../img/theme99.jpg';
import theme100 from '../img/theme100.jpg';

export const themes = [
  { id: 1, title: 'Tema 1', image: theme1 },
  { id: 2, title: 'Tema 2', image: theme2 },
  { id: 3, title: 'Tema 3', image: theme3 },
  { id: 4, title: 'Tema 4', image: theme4 },
  { id: 5, title: 'Tema 5', image: theme5 },
  { id: 6, title: 'Tema 6', image: theme6 },
  { id: 7, title: 'Tema 7', image: theme7 },
  { id: 8, title: 'Tema 8', image: theme8 },
  { id: 9, title: 'Tema 9', image: theme9 },
  { id: 10, title: 'Tema 10', image: theme10 },
  { id: 11, title: 'Tema 11', image: theme11 },
  { id: 12, title: 'Tema 12', image: theme12 },
  { id: 13, title: 'Tema 13', image: theme13 },
  { id: 14, title: 'Tema 14', image: theme14 },
  { id: 15, title: 'Tema 15', image: theme15 },
  { id: 16, title: 'Tema 16', image: theme16 },
  { id: 17, title: 'Tema 17', image: theme17 },
  { id: 18, title: 'Tema 18', image: theme18 },
  { id: 19, title: 'Tema 19', image: theme19 },
  { id: 20, title: 'Tema 20', image: theme20 },
  { id: 21, title: 'Tema 21', image: theme21 },
  { id: 22, title: 'Tema 22', image: theme22 },
  { id: 23, title: 'Tema 23', image: theme23 },
  { id: 24, title: 'Tema 24', image: theme24 },
  { id: 25, title: 'Tema 25', image: theme25 },
  { id: 26, title: 'Tema 26', image: theme26 },
  { id: 27, title: 'Tema 27', image: theme27 },
  { id: 28, title: 'Tema 28', image: theme28 },
  { id: 29, title: 'Tema 29', image: theme29 },
  { id: 30, title: 'Tema 30', image: theme30 },
  { id: 31, title: 'Tema 31', image: theme31 },
  { id: 32, title: 'Tema 32', image: theme32 },
  { id: 33, title: 'Tema 33', image: theme33 },
  { id: 34, title: 'Tema 34', image: theme34 },
  { id: 35, title: 'Tema 35', image: theme35 },
  { id: 36, title: 'Tema 36', image: theme36 },
  { id: 37, title: 'Tema 37', image: theme37 },
  { id: 38, title: 'Tema 38', image: theme38 },
  { id: 39, title: 'Tema 39', image: theme39 },
  { id: 40, title: 'Tema 40', image: theme40 },
  { id: 41, title: 'Tema 41', image: theme41 },
  { id: 42, title: 'Tema 42', image: theme42 },
  { id: 43, title: 'Tema 43', image: theme43 },
  { id: 44, title: 'Tema 44', image: theme44 },
  { id: 45, title: 'Tema 45', image: theme45 },
  { id: 46, title: 'Tema 46', image: theme46 },
  { id: 47, title: 'Tema 47', image: theme47 },
  { id: 48, title: 'Tema 48', image: theme48 },
  { id: 49, title: 'Tema 49', image: theme49 },
  { id: 50, title: 'Tema 50', image: theme50 },
  { id: 51, title: 'Tema 51', image: theme51 },
  { id: 52, title: 'Tema 52', image: theme52 },
  { id: 53, title: 'Tema 53', image: theme53 },
  { id: 54, title: 'Tema 54', image: theme54 },
  { id: 55, title: 'Tema 55', image: theme55 },
  { id: 56, title: 'Tema 56', image: theme56 },
  { id: 57, title: 'Tema 57', image: theme57 },
  { id: 58, title: 'Tema 58', image: theme58 },
  { id: 59, title: 'Tema 59', image: theme59 },
  { id: 60, title: 'Tema 60', image: theme60 },
  { id: 61, title: 'Tema 61', image: theme61 },
  { id: 62, title: 'Tema 62', image: theme62 },
  { id: 63, title: 'Tema 63', image: theme63 },
  { id: 64, title: 'Tema 64', image: theme64 },
  { id: 65, title: 'Tema 65', image: theme65 },
  { id: 66, title: 'Tema 66', image: theme66 },
  { id: 67, title: 'Tema 67', image: theme67 },
  { id: 68, title: 'Tema 68', image: theme68 },
  { id: 69, title: 'Tema 69', image: theme69 },
  { id: 70, title: 'Tema 70', image: theme70 },
  { id: 71, title: 'Tema 71', image: theme71 },
  { id: 72, title: 'Tema 72', image: theme72 },
  { id: 73, title: 'Tema 73', image: theme73 },
  { id: 74, title: 'Tema 74', image: theme74 },
  { id: 75, title: 'Tema 75', image: theme75 },
  { id: 76, title: 'Tema 76', image: theme76 },
  { id: 77, title: 'Tema 77', image: theme77 },
  { id: 78, title: 'Tema 78', image: theme78 },
  { id: 79, title: 'Tema 79', image: theme79 },
  { id: 80, title: 'Tema 80', image: theme80 },
  { id: 81, title: 'Tema 81', image: theme81 },
  { id: 82, title: 'Tema 82', image: theme82 },
  { id: 83, title: 'Tema 83', image: theme83 },
  { id: 84, title: 'Tema 84', image: theme84 },
  { id: 85, title: 'Tema 85', image: theme85 },
  { id: 86, title: 'Tema 86', image: theme86 },
  { id: 87, title: 'Tema 87', image: theme87 },
  { id: 88, title: 'Tema 88', image: theme88 },
  { id: 89, title: 'Tema 89', image: theme89 },
  { id: 90, title: 'Tema 90', image: theme90 },
  { id: 91, title: 'Tema 91', image: theme91 },
  { id: 92, title: 'Tema 92', image: theme92 },
  { id: 93, title: 'Tema 93', image: theme93 },
  { id: 94, title: 'Tema 94', image: theme94 },
  { id: 95, title: 'Tema 95', image: theme95 },
  { id: 96, title: 'Tema 96', image: theme96 },
  { id: 97, title: 'Tema 97', image: theme97 },
  { id: 98, title: 'Tema 98', image: theme98 },
  { id: 99, title: 'Tema 99', image: theme99 },
  { id: 100, title: 'Tema 100', image: theme100 },
];