import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaAngleLeft } from "react-icons/fa";

const BackButtonWhite = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="fixed z-50 top-0 left-0 p-2">
      <button 
        className="flex items-center text-neutral-50 px-2 py-3 rounded"
        onClick={handleGoBack}
      >
        <FaAngleLeft style={{ fontSize: '1.5rem' }} /> Back
      </button>
    </div>
  );
};

export default BackButtonWhite;
