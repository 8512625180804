import { useState, useEffect } from 'react';
import React, { useParams } from 'react-router-dom';
import axios from 'axios';

const EnableSalamKaut = () => {
  const { id } = useParams();
  const [isSalamKautEnabled, setIsSalamKautEnabled] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/clients/${id}`)
        .then(response => {
          console.log(response.data.salamkaut_activation);
          const salamKautStatus = JSON.parse(response.data.salamkaut_activation);
          setIsSalamKautEnabled(salamKautStatus);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  }, []);

  const toggleSalamKaut = () => {
    axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-salamkaut/${id}`, { salamkaut_activation: !isSalamKautEnabled })
      .then(response => {
          console.log(response.data.message); // Log success message
          setIsSalamKautEnabled(!isSalamKautEnabled);
          window.location.reload();
      })
      .catch(error => {
          console.error('Error updating salam kaut:', error);
      });
  };

  return (
    <div className="flex items-center my-5">
      <h1 className='text-secondary-500 font-bold'>Aktifkan Salam Kaut?</h1>
      <input
        type="checkbox"
        checked={isSalamKautEnabled}
        onChange={toggleSalamKaut}
        id="salamKautToggle"
        className="hidden"
      />
      <label
        htmlFor="salamKautToggle"
        className="flex items-center cursor-pointer bg-gray-100 w-14 h-7 rounded-full p-1 border border-gray-300 transition duration-300 ease-in-out ml-auto"
      >
        <div
          className={`${
            isSalamKautEnabled ? 'translate-x-7 bg-green-500' : 'translate-x-0 bg-gray-400'
          } w-5 h-5 rounded-full shadow-md transform transition-transform`}
        ></div>
      </label>
    </div>

  );
};

export default EnableSalamKaut;
