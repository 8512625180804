// src/assets/Images.js
import bg1 from '../img/bg1.jpg';
import ft1 from '../img/ft1.png';
import fb1 from '../img/fb1.png';

import bg2 from '../img/bg2.jpg';
import ft2 from '../img/ft2.png';
import fb2 from '../img/fb2.png';

import bg3 from '../img/bg3.jpg';
import ft3 from '../img/ft3.png';
import fb3 from '../img/fb3.png';

import bg4 from '../img/bg4.jpg';
import ft4 from '../img/ft4.png';
import fb4 from '../img/fb4.png';

import bg5 from '../img/bg5.jpg';
import ft5 from '../img/ft5.png';
import fb5 from '../img/fb5.png';

import bg6 from '../img/bg6.jpg';
import ft6 from '../img/ft6.png';
import fb6 from '../img/fb6.png';
import fc6 from '../img/fc6.png';

import bg7 from '../img/bg7.jpg';
import ft7 from '../img/ft7.png';
import fb7 from '../img/fb7.png';
import fc7 from '../img/fc7.png';

import bg8 from '../img/bg8.jpg';
import ft8 from '../img/ft8.png';
import fb8 from '../img/fb8.png';

import bg9 from '../img/bg9.jpg';
import fc9 from '../img/fc9.png';

import bg10 from '../img/bg10.jpg';
import fc10 from '../img/fc10.png';

import bg11 from '../img/bg11.jpg';
import ft11 from '../img/ft11.png';
import fb11 from '../img/fb11.png';
import fc11 from '../img/fc11.png';

import bg12 from '../img/bg12.jpg';
import fc12 from '../img/fc12.png';

import bg13 from '../img/bg13.jpg';
import ft13 from '../img/ft13.png';
import fb13 from '../img/fb13.png';

import bg14 from '../img/bg14.jpg';
import ft14 from '../img/ft14.png';
import fb14 from '../img/fb14.png';
import fc14 from '../img/fc14.png';

import bg15 from '../img/bg15.jpg';
import fc15 from '../img/fc15.png';

import bg16 from '../img/bg16.jpg';
import fc16 from '../img/fc16.png';

import bg17 from '../img/bg17.jpg';
import fc17 from '../img/fc17.png';

import bg18 from '../img/bg18.jpg';
import ft18 from '../img/ft18.png';
import fb18 from '../img/fb18.png';

import bg19 from '../img/bg19.jpg';
import ft19 from '../img/ft19.png';
import fb19 from '../img/fb19.png';

import bg20 from '../img/bg20.jpg';
import fc20 from '../img/fc20.png';

import bg21 from '../img/bg21.jpg';
import ft21 from '../img/ft21.png';
import fb21 from '../img/fb21.png';

import bg22 from '../img/bg22.jpg';
import fc22 from '../img/fc22.png';

import bg23 from '../img/bg23.jpg';
import ft23 from '../img/ft23.png';
import fb23 from '../img/fb23.png';

import bg24 from '../img/bg24.jpg';
import ft24 from '../img/ft24.png';
import fb24 from '../img/fb24.png';

import bg25 from '../img/bg25.jpg';
import ft25 from '../img/ft25.png';
import fb25 from '../img/fb25.png';

import bg26 from '../img/bg26.jpg';
import ft26 from '../img/ft26.png';
import fb26 from '../img/fb26.png';

import bg27 from '../img/bg27.jpg';
import ft27 from '../img/ft27.png';
import fb27 from '../img/fb27.png';

import bg28 from '../img/bg28.jpg';
import fc28 from '../img/fc28.png';

import bg29 from '../img/bg29.jpg';
import ft29 from '../img/ft29.png';
import fb29 from '../img/fb29.png';
import fc29 from '../img/fc29.png';

import bg30 from '../img/bg30.jpg';
import ft30 from '../img/ft30.png';
import fb30 from '../img/fb30.png';
import fc30 from '../img/fc30.png';

import bg31 from '../img/bg31.jpg';
import fc31 from '../img/fc31.png';

import bg32 from '../img/bg32.jpg';
import ft32 from '../img/ft32.png';
import fb32 from '../img/fb32.png';

import bg33 from '../img/bg33.jpg';
import ft33 from '../img/ft33.png';
import fb33 from '../img/fb33.png';

import bg34 from '../img/bg34.jpg';
import fc34 from '../img/fc34.png';

import bg35 from '../img/bg35.jpg';
import ft35 from '../img/ft35.png';
import fb35 from '../img/fb35.png';

import bg36 from '../img/bg36.jpg';
import ft36 from '../img/ft36.png';
import fb36 from '../img/fb36.png';

import bg37 from '../img/bg37.jpg';
import ft37 from '../img/ft37.png';
import fb37 from '../img/fb37.png';

import bg38 from '../img/bg38.jpg';
import ft38 from '../img/ft38.png';
import fb38 from '../img/fb38.png';
import fc38 from '../img/fc38.png';

import bg39 from '../img/bg39.jpg';
import ft39 from '../img/ft39.png';
import fb39 from '../img/fb39.png';
import fc39 from '../img/fc39.png';

import bg40 from '../img/bg40.jpg';
import ft40 from '../img/ft40.png';
import fb40 from '../img/fb40.png';
import fc40 from '../img/fc40.png';

import bg41 from '../img/bg41.jpg';
import ft41 from '../img/ft41.png';
import fb41 from '../img/fb41.png';
import fc41 from '../img/fc41.png';

import bg42 from '../img/bg42.jpg';
import ft42 from '../img/ft42.png';
import fb42 from '../img/fb42.png';

import bg43 from '../img/bg43.jpg';
import fc43 from '../img/fc43.png';

import bg44 from '../img/bg44.jpg';
import ft44 from '../img/ft44.png';
import fb44 from '../img/fb44.png';
import fc44 from '../img/fc44.png';

import bg45 from '../img/bg45.jpg';
import ft45 from '../img/ft45.png';
import fb45 from '../img/fb45.png';
import fc45 from '../img/fc45.png';

import bg46 from '../img/bg46.jpg';
import ft46 from '../img/ft46.png';
import fb46 from '../img/fb46.png';

import bg47 from '../img/bg47.jpg';
import ft47 from '../img/ft47.png';
import fb47 from '../img/fb47.png';

import bg48 from '../img/bg48.jpg';
import ft48 from '../img/ft48.png';
import fb48 from '../img/fb48.png';

import bg49 from '../img/bg49.jpg';
import fc49 from '../img/fc49.png';

import fc50 from '../img/fc50.png';
import ft50 from '../img/ft50.png';
import fb50 from '../img/fb50.png';

import bg51 from '../img/bg51.jpg';
import ft51 from '../img/ft51.png';
import fb51 from '../img/fb51.png';
import fc51 from '../img/fc51.png';

import bg52 from '../img/bg52.jpg';
import ft52 from '../img/ft52.png';
import fb52 from '../img/fb52.png';

import bg53 from '../img/bg53.jpg';
import ft53 from '../img/ft53.png';
import fb53 from '../img/fb53.png';

import bg54 from '../img/bg54.jpg';
import ft54 from '../img/ft54.png';
import fb54 from '../img/fb54.png';
import fc54 from '../img/fc54.png';

import bg55 from '../img/bg55.jpg';
import ft55 from '../img/ft55.png';
import fb55 from '../img/fb55.png';

import ft56 from '../img/ft56.png';
import fb56 from '../img/fb56.png';

import bg57 from '../img/bg57.jpg';
import fc57 from '../img/fc57.png';

import bg58 from '../img/bg58.jpg';
import ft58 from '../img/ft58.png';
import fb58 from '../img/fb58.png';

import bg59 from '../img/bg59.jpg';
import ft59 from '../img/ft59.png';
import fb59 from '../img/fb59.png';
import fc59 from '../img/fc59.png';

import bg60 from '../img/bg60.jpg';
import ft60 from '../img/ft60.png';
import fb60 from '../img/fb60.png';
import fc60 from '../img/fc60.png';

import bg61 from '../img/bg61.jpg';
import ft61 from '../img/ft61.png';
import fb61 from '../img/fb61.png';
import fc61 from '../img/fc61.png';

import bg62 from '../img/bg62.jpg';
import ft62 from '../img/ft62.png';
import fb62 from '../img/fb62.png';

import bg63 from '../img/bg63.jpg';
import ft63 from '../img/ft63.png';
import fb63 from '../img/fb63.png';

import fc64 from '../img/fc64.png';
import ft64 from '../img/ft64.png';
import fb64 from '../img/fb64.png';

import bg65 from '../img/bg65.jpg';
import fc65 from '../img/fc65.png';

import bg66 from '../img/bg66.jpg';
import ft66 from '../img/ft66.png';
import fb66 from '../img/fb66.png';
import fc66 from '../img/fc66.png';

import bg67 from '../img/bg67.jpg';
import ft67 from '../img/ft67.png';
import fb67 from '../img/fb67.png';
import fc67 from '../img/fc67.png';

import bg68 from '../img/bg68.jpg';
import ft68 from '../img/ft68.png';
import fb68 from '../img/fb68.png';
import fc68 from '../img/fc68.png';

import bg69 from '../img/bg69.jpg';
import ft69 from '../img/ft69.png';
import fb69 from '../img/fb69.png';
import fc69 from '../img/fc69.png';

import bg70 from '../img/bg70.jpg';
import ft70 from '../img/ft70.png';
import fb70 from '../img/fb70.png';

import bg71 from '../img/bg71.jpg';
import ft71 from '../img/ft71.png';
import fb71 from '../img/fb71.png';
import fc71 from '../img/fc71.png';

import bg72 from '../img/bg72.jpg';
import ft72 from '../img/ft72.png';
import fb72 from '../img/fb72.png';
import fc72 from '../img/fc72.png';

import bg73 from '../img/bg73.jpg';
import ft73 from '../img/ft73.png';
import fb73 from '../img/fb73.png';
import fc73 from '../img/fc73.png';

import bg74 from '../img/bg74.jpg';
import ft74 from '../img/ft74.png';
import fc74 from '../img/fc74.png';

import bg75 from '../img/bg75.jpg';
import ft75 from '../img/ft75.png';
import fb75 from '../img/fb75.png';
import fc75 from '../img/fc75.png';

import bg76 from '../img/bg76.jpg';
import ft76 from '../img/ft76.png';
import fb76 from '../img/fb76.png';
import fc76 from '../img/fc76.png';

import bg77 from '../img/bg77.jpg';
import ft77 from '../img/ft77.png';
import fb77 from '../img/fb77.png';

import bg78 from '../img/bg78.jpg';
import ft78 from '../img/ft78.png';
import fb78 from '../img/fb78.png';
import fc78 from '../img/fc78.png';

import bg79 from '../img/bg79.jpg';
import ft79 from '../img/ft79.png';
import fb79 from '../img/fb79.png';
import fc79 from '../img/fc79.png';

import bg80 from '../img/bg80.jpg';
import fc80 from '../img/fc80.png';

import bg81 from '../img/bg81.jpg';
import ft81 from '../img/ft81.png';
import fb81 from '../img/fb81.png';
import fc81 from '../img/fc81.png';

import bg82 from '../img/bg82.jpg';
import ft82 from '../img/ft82.png';
import fb82 from '../img/fb82.png';
import fc82 from '../img/fc82.png';

import bg83 from '../img/bg83.jpg';
import ft83 from '../img/ft83.png';
import fb83 from '../img/fb83.png';

import bg84 from '../img/bg84.jpg';
import ft84 from '../img/ft84.png';
import fb84 from '../img/fb84.png';

import bg85 from '../img/bg85.jpg';
import ft85 from '../img/ft85.png';
import fb85 from '../img/fb85.png';

import bg86 from '../img/bg86.jpg';
import ft86 from '../img/ft86.png';
import fb86 from '../img/fb86.png';
import fc86 from '../img/fc86.png';

import bg87 from '../img/bg87.jpg';
import ft87 from '../img/ft87.png';
import fb87 from '../img/fb87.png';
import fc87 from '../img/fc87.png';

import bg88 from '../img/bg88.jpg';
import fc88 from '../img/fc88.png';

import bg89 from '../img/bg89.jpg';
import ft89 from '../img/ft89.png';
import fb89 from '../img/fb89.png';
import fc89 from '../img/fc89.png';

import bg90 from '../img/bg90.jpg';
import ft90 from '../img/ft90.png';
import fb90 from '../img/fb90.png';
import fc90 from '../img/fc90.png';

import bg91 from '../img/bg91.jpg';
import ft91 from '../img/ft91.png';
import fb91 from '../img/fb91.png';

import bg92 from '../img/bg92.jpg';
import ft92 from '../img/ft92.png';
import fb92 from '../img/fb92.png';
import fc92 from '../img/fc92.png';

import bg93 from '../img/bg93.jpg';
import ft93 from '../img/ft93.png';
import fb93 from '../img/fb93.png';

import bg94 from '../img/bg94.jpg';
import ft94 from '../img/ft94.png';
import fb94 from '../img/fb94.png';
import fc94 from '../img/fc94.png';

import bg95 from '../img/bg95.jpg';
import fc95 from '../img/fc95.png';

import fc96 from '../img/fc96.png';

import bg97 from '../img/bg97.jpg';
import fc97 from '../img/fc97.png';

import bg98 from '../img/bg98.jpg';
import ft98 from '../img/ft98.png';
import fb98 from '../img/fb98.png';

import bg99 from '../img/bg99.jpg';
import ft99 from '../img/ft99.png';
import fb99 from '../img/fb99.png';

import ft100 from '../img/ft100.png';
import fb100 from '../img/fb100.png';
import fc100 from '../img/fc100.png';

export default {
  bg1, ft1, fb1,
  bg2, ft2, fb2,
  bg3, ft3, fb3,
  bg4, ft4, fb4,
  bg5, ft5, fb5,
  bg6, ft6, fb6, fc6,
  bg7, ft7, fb7, fc7,
  bg8, ft8, fb8,
  bg9, fc9,
  bg10, fc10,
  bg11, ft11, fb11, fc11,
  bg12, fc12,
  bg13, ft13, fb13,
  bg14, ft14, fb14, fc14,
  bg15, fc15,
  bg16, fc16,
  bg17, fc17,
  bg18, ft18, fb18,
  bg19, ft19, fb19,
  bg20, fc20,
  bg21, ft21, fb21,
  bg22, fc22,
  bg23, ft23, fb23,
  bg24, ft24, fb24,
  bg25, ft25, fb25,
  bg26, ft26, fb26,
  bg27, ft27, fb27,
  bg28, fc28,
  bg29, ft29, fb29, fc29,
  bg30, ft30, fb30, fc30,
  bg31, fc31,
  bg32, ft32, fb32,
  bg33, ft33, fb33,
  bg34, fc34,
  bg35, ft35, fb35,
  bg36, ft36, fb36,
  bg37, ft37, fb37,
  bg38, ft38, fb38, fc38,
  bg39, ft39, fb39, fc39,
  bg40, ft40, fb40, fc40,
  bg41, ft41, fb41, fc41,
  bg42, ft42, fb42,
  bg43, fc43,
  bg44, ft44, fb44, fc44,
  bg45, ft45, fb45, fc45,
  bg46, ft46, fb46,
  bg47, ft47, fb47,
  bg48, ft48, fb48,
  bg49, fc49,
  fc50, ft50, fb50,
  bg51, ft51, fb51, fc51,
  bg52, ft52, fb52,
  bg53, ft53, fb53,
  bg54, ft54, fb54, fc54,
  bg55, ft55, fb55,
  ft56, fb56,
  bg57, fc57,
  bg58, ft58, fb58,
  bg59, ft59, fb59, fc59,
  bg60, ft60, fb60, fc60,
  bg61, ft61, fb61, fc61,
  bg62, ft62, fb62,
  bg63, ft63, fb63,
  fc64, ft64, fb64,
  bg65, fc65,
  bg66, ft66, fb66, fc66,
  bg67, ft67, fb67, fc67,
  bg68, ft68, fb68, fc68,
  bg69, ft69, fb69, fc69,
  bg70, ft70, fb70,
  bg71, ft71, fb71, fc71,
  bg72, ft72, fb72, fc72,
  bg73, ft73, fb73, fc73,
  bg74, ft74, fc74,
  bg75, ft75, fb75, fc75,
  bg76, ft76, fb76, fc76,
  bg77, ft77, fb77,
  bg78, ft78, fb78, fc78,
  bg79, ft79, fb79, fc79,
  bg80, fc80,
  bg81, ft81, fb81, fc81,
  bg82, ft82, fb82, fc82,
  bg83, ft83, fb83,
  bg84, ft84, fb84,
  bg85, ft85, fb85,
  bg86, ft86, fb86, fc86,
  bg87, ft87, fb87, fc87,
  bg88, fc88,
  bg89, ft89, fb89, fc89,
  bg90, ft90, fb90, fc90,
  bg91, ft91, fb91,
  bg92, ft92, fb92, fc92,
  bg93, ft93, fb93,
  bg94, ft94, fb94, fc94,
  bg95, fc95,
  fc96,
  bg97, fc97,
  bg98, ft98, fb98,
  bg99, ft99, fb99,
  ft100, fb100, fc100,
};
